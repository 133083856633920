// import { useHistory, useLocation } from 'react-router-dom';
import linq from 'linq';
import moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import GeneralApi from '../../api/GeneralApi';
import EventWidget from '../../components/event_widget/EventWidget';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Masonry from '../../components/masonry/Masonry';
import LocationHelper from '../../helpers/LocationHelper';
import { IEvent } from '../../interfaces/IEvent';
import { DatesModal } from '../../modals/Dates/DatesModal';

const SearchPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState('Loading featured events...');
  const [selectedEvent, setSelectedEvent] = useState<IEvent>(null);
  const [searchResults, setSearchResults] = useState<Array<IEvent>>([]);
  const eventUrlDetails = LocationHelper.getLocation().pathname;
  const searchTerm = eventUrlDetails.split('/')[2];

  useEffect(() => {
    if (!searchTerm || searchTerm.length === 0 || searchTerm === 'search') {
      setSearchResults(null);
      return;
    }

    setLoading('Searching for events...');

    GeneralApi.request('POST', '/api/SearchEvents', { seatingPlanOnly: false, term: searchTerm, organisationTag: '', allTime: false })
      .then((response) => {
        setLoading(null);
        setSearchResults(response);
      })
      .catch((message) => {
        alert(message);
        setLoading(null);
      });
  }, [location.search]);

  let events = linq
    .from(searchResults)
    .where((event) => {
      const now = event && moment().tz(event.TimeZoneIana);
      const onlineFrom = event && moment(event.OnlineFromDate).tz(event.TimeZoneIana);
      const salesStarted = event && onlineFrom <= now;
      return salesStarted;
    })
    .orderBy((e) => moment.utc(e.MinDate).unix())
    .groupBy((e) => e.TourId)
    .selectMany((e) => {
      const event = e.first();
      if (event.TourId) {
        return [{ ...e.first(), MinDate: event.MinDate, MaxDate: e.last().MaxDate }];
      } else {
        return e.toArray();
      }
    })
    .orderBy((e) => moment.utc(e.MinDate).unix())
    .toArray();

  return (
    <>
      <Helmet>
        <title>Seaty - A simple box office platform</title>
        <meta name="description" content={`The simple, free online box office. Sign up and let us help you sell your tickets!`} />
      </Helmet>
      <Header initialSearchTerm={searchTerm} />
      {searchResults && searchResults.length === 0 ? (
        <div className="strip-block" style={{ paddingTop: '100px', paddingBottom: '300px' }}>
          <div className="row content">
            <div style={{ textAlign: 'left' }} className="col-sm-6">
              <div>Oh dear! 🕵️‍♂️</div>
              <div className="text">
                It looks like we couldn't find any events that match your search term. <br />
                <br />
                We're sorry about that, but don't worry, there are still plenty of other exciting events to explore on Seaty! 🎉
                <br />
                <br />
                Feel free to modify your search term, or try browsing the events on our home page. 🔍
                <br />
                <br />
                We hope that you'll find the perfect event on Seaty soon! 🎫
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="event-widgets">
          <h1 className="group">
            <span className="highlight">{events.length}</span> Search Results
          </h1>
          <Masonry>
            {events.map((event) => (
              <EventWidget
                key={event.Id}
                event={event}
                onClick={() => {
                  if (!event.TourId && event.Dates.length === 1) {
                    navigate(`/${event.EventTag}/tickets/${moment(event.Dates[0].DateAsString).format('DDMMMYYYY/HHmm')}`);
                  } else {
                    setSelectedEvent(event);
                  }
                }}
              />
            ))}
          </Masonry>
        </div>
      )}
      <Footer />
      {selectedEvent && <DatesModal onClose={() => setSelectedEvent(null)} isAdmin={false} event={selectedEvent} />}
      {loading && <Loader>{loading}</Loader>}
    </>
  );
};

export default SearchPage;
