import React, { FunctionComponent } from 'react';

export interface IProps {
  checked: boolean;
  onChange: (e: boolean) => void;
  trueLabel: string;
  falseLabel: string;
}

const Switch: FunctionComponent<IProps> = (props) => {
  var text = '';

  if (props.checked) {
    if (props.trueLabel != null) {
      text = props.trueLabel;
    }
  } else {
    if (props.falseLabel != null) {
      text = props.falseLabel;
    }
  }

  return (
    <div className="checkbox checkbox-slider--b-flat checkbox-slider-md">
      <label>
        <input onChange={(e) => props.onChange(e.target.checked)} type="checkbox" checked={props.checked}></input>
        <span>{text}</span>
      </label>
    </div>
  );
};

export default Switch;
