import linq from 'linq';
import moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import EventWidget from '../../components/event_widget/EventWidget';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Masonry from '../../components/masonry/Masonry';
import EnvironmentVariables from '../../EnvironmentVariables';
import CacheHelper from '../../helpers/CacheHelper';
import JsonFileHelper from '../../helpers/JsonFileHelper';
import { IEvent } from '../../interfaces/IEvent';
import { DatesModal } from '../../modals/Dates/DatesModal';
import EventList from '../../components/EventList';

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState('Loading featured events...');
  const [featuredEvents, setFeaturedEvents] = useState<Array<IEvent>>(CacheHelper.featuredEvents);
  const [selectedEvent, setSelectedEvent] = useState<IEvent>(null);

  useEffect(() => {
    if (CacheHelper.featuredEvents.length === 0) {
      try {
        setLoading('Loading featured events...');
        JsonFileHelper.download(`https://seatylive.blob.core.windows.net/featured-cache${EnvironmentVariables.isDev() ? '-test' : ''}/featuredEvents.json?_=${new Date().getTime()}`)
          .then((result) => {
            setFeaturedEvents(result.Events);
            CacheHelper.featuredEvents = result.Events;
            setLoading(null);
          })
          .catch((e) => {
            alert(JSON.stringify(e));
          });
      } catch (ex) {
        alert(JSON.stringify(ex));
      }
    } else {
      setLoading(null);
    }
  }, []);

  let events = linq
    .from(featuredEvents)
    .where((event) => {
      const now = event && moment().tz(event.TimeZoneIana);
      const onlineFrom = event && moment(event.OnlineFromDate).tz(event.TimeZoneIana);
      const salesStarted = event && onlineFrom <= now;

      const maxDate = event && moment(event.MaxDate).add(12, 'hours').tz(event.TimeZoneIana);
      const maxDateInReasonableRange = event && maxDate >= now;

      return salesStarted && maxDateInReasonableRange && (event.TourId == null || event.TourId != 13);
    })
    .orderBy((e) => moment.utc(e.MinDate).unix())
    .groupBy((e) => e.TourId)
    .selectMany((e) => {
      const event = e.first();
      if (event.TourId) {
        return [{ ...e.first(), MinDate: event.MinDate, MaxDate: e.last().MaxDate }];
      } else {
        return e.toArray();
      }
    })
    .orderBy((e) => moment.utc(e.MinDate).unix())
    .toArray();

  const featuredOrganisationsWithMoreThanOneEvent = linq
    .from(events)
    .groupBy((e) => e.Organisation.Id)
    .where((o) => o.count() > 0);

  const maxEvents = 2;

  const strictlyEvents = events.filter((e) => (e.Organisation.Id === 280 || e.Organisation.Id === 332) && moment(e.MaxDate).add(-1, 'days') > moment());

  return (
    <>
      <Helmet>
        <title>Seaty - A simple box office platform</title>
        <meta name="description" content={`The simple, free online box office. Sign up and let us help you sell your tickets!`} />
      </Helmet>
      <Header alwaysShowSearch={true} />
      {/* 
      <div className="poster">
        <div className="poster-content">
          <div className="poster-call-to-action">
            <h1>
              You think it.
              <br />
              We ticket it.
            </h1>

            <p>
              From your debut event to your grandest yet, <br />
              ticket selling is made easy with Seaty.
            </p>
            <div className="chunky-buttons">
              <button className="green">Create an event</button>
            </div>
            <img src="https://seatylive.blob.core.windows.net/images/CreateEventLight.png" />
          </div>
        </div>
      </div> */}
      {/* 
      <div className="poster poster-spacer-top">
        <h2>Check out upcoming events!</h2>
      </div> */}

      <div className="events-list-strip-block-container">
        <div className="events-list-strip-block-rows">
          {/* <div className="strip-block events-list-strip-block">
          Strictly Tickets
          <EventList events={strictlyEvents} />
        </div> */}

          {featuredOrganisationsWithMoreThanOneEvent
            .select((o) => {
              var organisation = o.first().Organisation;

              const allVenuesTheSame = o.count() === 0 ? true : o.toArray().every((obj) => obj.Venue.Name === o.first().Venue.Name);

              return (
                <div key={organisation.Id} className={`strip-block events-list-strip-block ${o.count() > 1 ? 'events-list-more-than-one-event' : ''}`}>
                  <div className="events-list-title">
                    <Link className="events-list-see-more" to={EnvironmentVariables.HOME_URL + '/Organisation/' + organisation.OrganisationTag}>
                      {organisation.Name}
                    </Link>
                    {/* 
                    {o.count() > maxEvents && (
                      <Link className="events-list-see-more" to={EnvironmentVariables.HOME_URL + '/Organisation/' + organisation.OrganisationTag}>
                        See all events
                      </Link>
                    )} */}
                  </div>

                  <EventList hideOrganisation={organisation.Featured} events={o.take(maxEvents).toArray()} />
                  {/* {o.count() > maxEvents && (
                  <div className="events-list-title">
                    <Link className="events-list-see-more" to={EnvironmentVariables.HOME_URL + '/Organisation/' + organisation.OrganisationTag}>
                      See all {organisation.Name} events
                    </Link>
                  </div>
                )} */}
                </div>
              );
            })
            .toArray()}
        </div>
      </div>

      {/* 
      <div className="event-widgets">
        <h1 className="group">
          Strictly Theatre Company <span className="highlight">Tours</span>
        </h1>
        <Masonry>
          {events
            .filter((e) => (e.Organisation.Id === 280 || e.Organisation.Id === 332) && moment(e.MaxDate).add(-1, 'days') > moment())
            .map((event) => (
              <EventWidget
                key={event.Id}
                event={event}
                onClick={() => {
                  if (!event.TourId && event.Dates.length === 1) {
                    navigate(`/${event.EventTag}/tickets/${moment(event.Dates[0].DateAsString).format('DDMMMYYYY/HHmm')}`);
                  } else {
                    setSelectedEvent(event);
                  }
                }}
              />
            ))}
        </Masonry>

        <h1 className="group">
          Take a look at our <span className="highlight">Featured Events</span>
        </h1>
        <Masonry>
          {events
            .filter((e) => e.Organisation.Id !== 280 && e.Organisation.Id !== 332 && moment(e.MaxDate).add(0, 'days') > moment())
            .map((event) => (
              <EventWidget
                key={event.Id}
                event={event}
                onClick={() => {
                  // if (!event.TourId && event.Dates.length === 1) {
                  //   navigate(`/${event.EventTag}/tickets/${moment(event.Dates[0].DateAsString).format('DDMMMYYYY/HHmm')}`);
                  // } else {
                  //   setSelectedEvent(event);
                  // }
                  setSelectedEvent(event);
                }}
              />
            ))}
        </Masonry>
      </div> */}

      <Footer />
      {selectedEvent && <DatesModal onClose={() => setSelectedEvent(null)} isAdmin={false} event={selectedEvent} />}
      {loading && <Loader>{loading}</Loader>}
    </>
  );
};

export default HomePage;
