import { FunctionComponent, useState } from 'react';
import NumberHelper from '../../../../helpers/NumberHelper';
import SVGMinus from '../../../../svg/SVGMinus';
import SVGPlus from '../../../../svg/SVGPlus';

export interface IProps {
  title: string;
  subtitle?: string;
  titleColour?: string;
  subtitleColour?: string;
  amount: string;
  people?: string;
  percentage?: number;
  total: string;
  child?: any;
  wording?: string;
}

const EventSummary_TicketSalesRow: FunctionComponent<IProps> = (props) => {
  const [showChild, setShowChild] = useState(false);

  return (
    <div>
      <div onClick={() => setShowChild(!showChild)} className="field" style={{ cursor: props.child ? 'pointer' : '' }}>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              {!props.child ? null : (
                <td style={{ width: '40px', paddingRight: '8px' }}>
                  {showChild ? (
                    <button className="toggle collapse">
                      <SVGMinus />
                    </button>
                  ) : (
                    <button className="toggle expand">
                      <SVGPlus />
                    </button>
                  )}
                </td>
              )}
              <td style={{ width: '40%' }}>
                <div style={{ color: props.titleColour ? props.titleColour : '' }}>{props.title}</div>
                <div style={{ color: props.subtitleColour ? props.subtitleColour : '' }}>{props.subtitle}</div>
              </td>
              <td className="right" style={{ width: '10%' }}>
                {NumberHelper.isNumeric(props.percentage) ? <span style={{ color: '#bbbbbb' }}> {props.percentage == 100 ? 100 : props.percentage.toFixed(1)}%</span> : null}
              </td>
              <td className="right" style={{ width: '25%' }}>
                <div>
                  {props.people ? (
                    <div>
                      {props.amount}
                      <div>
                        {props.people} <span style={{ color: '#bbbbbb' }}>People</span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {props.amount}
                      {props.wording && <span style={{ color: '#bbbbbb' }}> {props.wording}</span>}
                    </div>
                  )}
                </div>
              </td>
              <td className="right" style={{ width: '25%' }}>
                <div>{props.total}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {showChild && props.child ? (
        <div style={{ paddingLeft: '25px' }} className="animated fadeIn">
          {props.child}
        </div>
      ) : null}
    </div>
  );
};

export default EventSummary_TicketSalesRow;
