import React, { useEffect, useState } from 'react';
import './Masonry.scss';

interface IProps {
  maxColumns?: number;
}

const Masonry: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const getHowManyFit = (): number => {
    var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    var maxColumns = props.maxColumns ? props.maxColumns : 4;
    var eventBoxWidth = 330;
    var columns = Math.floor(windowWidth / eventBoxWidth);
    if (columns < 1) {
      columns = 1;
    }

    if (columns > maxColumns) {
      columns = maxColumns;
    }

    return columns;
  };

  const [howManyFit, setHowManyFit] = useState(getHowManyFit());

  const onWindowResize = () => {
    const columns = getHowManyFit();
    if (howManyFit !== columns) {
      setHowManyFit(columns);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  });

  var { children } = props as any;
  var columns: Array<any> = [];

  for (var arrIndex = 0; arrIndex < howManyFit; arrIndex++) {
    columns.push([]);
  }

  var row = 0;

  for (var childIndex = 0; childIndex < children.length; childIndex++) {
    var child = children[childIndex];
    var columnIndex = childIndex - row * howManyFit;

    columns[columnIndex].push(child);

    if (columnIndex === howManyFit - 1) {
      row += 1;
    }
  }

  var tableColumns = [];
  var amountDivided = howManyFit / 2;
  for (var i = 0; i < howManyFit; i++) {
    tableColumns.push(
      <td className={`column${i < amountDivided ? ' left' : ''}${i > amountDivided ? ' right' : ''}${i === amountDivided ? ' mid' : ''}`} key={'column_' + i}>
        {columns[i]}
      </td>,
    );
  }

  return (
    <div className="masonry">
      {
        <table>
          <tbody>
            <tr>{tableColumns}</tr>
          </tbody>
        </table>
      }
    </div>
  );
};

export default Masonry;
