import linq from 'linq';
import moment from 'moment';
import { FunctionComponent } from 'react';
import CurrencyHelper from '../../../../helpers/CurrencyHelper';
import { IEvent } from '../../../../interfaces/IEvent';

import EventSummary_TicketSalesRow from './EventSummary_TicketSalesRow';

interface IEventDateSale {
  EventDateId: number;
  EventDateAsString: string;
  SoldTickets: any;
  Discounts: any[];
  PriceAsInt: number;
  TicketsByCategory: any;
}

export interface IProps {
  currency: string;
  eventDateSales: Array<IEventDateSale>;
  event: IEvent;
}

const EventSummary_TicketSales: FunctionComponent<IProps> = (props) => {
  var event = props.event;

  var rows = [];
  const hasSeatingPlans = event.UseSeatingPlan && event.SeatingPlans && event.SeatingPlans.length > 0;

  var ticketsEnum = linq.from(props.eventDateSales).orderBy((m) => moment(m.EventDateAsString).unix());

  var totalSoldTickets = ticketsEnum.sum((t) => {
    return (t as any).SoldTickets;
  });

  var totalTickets = ticketsEnum.sum((t) => {
    const eventDate: any = linq.from(event.Dates).firstOrDefault((ed) => ed.Id == t.EventDateId);
    if (!eventDate) return 0;

    const categoryGroupsInDate = linq.from(event.UnallocatedCategoryGroups).where((g: any) => linq.from(g.Categories).any((c: any) => eventDate.GATicketCategoryIds.includes(c.Id)));
    const totalGeneralAdmissionTickets = categoryGroupsInDate.sum((c: any) => c.Quantity);
    return (hasSeatingPlans ? linq.from(event.SeatingPlans).sum((s) => s.SeatCount) : 0) + totalGeneralAdmissionTickets;
  });

  var totalDiscountsAsInt = 0;
  const dayFormat = 'ddd Do MMM YY';

  const getEventDateTotals = (eventDateSummary: IEventDateSale) => {
    const eventDate: any = linq.from(event.Dates).firstOrDefault((ed) => ed.Id == eventDateSummary.EventDateId);
    if (!eventDate)
      return {
        total: 0,
        amount: '0/0',
        soldTickets: 0,
        totalTickets: 0,
        percentage: 0,
      };

    const categoryGroupsInDate = linq.from(event.UnallocatedCategoryGroups).where((g: any) => linq.from(g.Categories).any((c: any) => eventDate.GATicketCategoryIds.includes(c.Id)));
    const totalGeneralAdmissionTickets = categoryGroupsInDate.sum((c: any) => c.Quantity);

    var totalTicketsForEventDate = (hasSeatingPlans ? linq.from(event.SeatingPlans).sum((s) => s.SeatCount) : 0) + totalGeneralAdmissionTickets;
    var percentage = eventDateSummary.SoldTickets > totalTicketsForEventDate ? 100.0 : (eventDateSummary.SoldTickets / totalTicketsForEventDate) * 100.0;
    var amount = eventDateSummary.SoldTickets + '/' + totalTicketsForEventDate;
    var totalDiscounts = linq.from(eventDateSummary.Discounts).sum((t) => {
      return (t as any).Amount;
    });

    totalDiscountsAsInt += totalDiscounts;

    return {
      total: eventDateSummary.PriceAsInt - totalDiscounts,
      amount,
      soldTickets: eventDateSummary.SoldTickets,
      totalTickets: totalTicketsForEventDate,
      percentage,
    };
  };

  const getDateTimeNode = (eventDateSummary: IEventDateSale, timeOnly: boolean = false) => {
    var dateString = moment(eventDateSummary.EventDateAsString).format(dayFormat);
    var timeString = moment(eventDateSummary.EventDateAsString).format('h:mma');

    var children = [];
    const { total, amount, percentage } = getEventDateTotals(eventDateSummary);

    eventDateSummary.Discounts.forEach((discount) => {
      children.push(
        <EventSummary_TicketSalesRow
          key={'category_discount_row_' + eventDateSummary.EventDateId + '_' + discount.Id}
          title={discount.Name}
          titleColour={'#41abbb'}
          subtitle={''}
          subtitleColour={'#41abbb'}
          percentage={null}
          amount={discount.Quantity}
          total={'-' + CurrencyHelper.formatCurrency(props.currency, discount.Amount)}
        ></EventSummary_TicketSalesRow>,
      );
    });

    linq
      .from(eventDateSummary.TicketsByCategory)
      .where((tc) => tc.SeatCategory)
      .groupBy((tc) => tc.SeatCategory.Id)
      .toArray()
      .forEach((group) => {
        const seatCategory = group.first().SeatCategory;
        if (group.count() == 1) {
          const ticketCategory = group.first();

          children.push(
            <EventSummary_TicketSalesRow
              key={'category_row_' + eventDateSummary.EventDateId + '_' + ticketCategory.Id}
              subtitle={ticketCategory.Name}
              subtitleColour={ticketCategory.Colour}
              title={ticketCategory.SeatCategory ? ticketCategory.SeatCategory.Name : ''}
              titleColour={ticketCategory.SeatCategory ? ticketCategory.SeatCategory.Colour : ''}
              percentage={null}
              amount={ticketCategory.SeatCount + '/' + seatCategory.SeatCount}
              total={ticketCategory.PriceAsString}
              people={ticketCategory.People > 1 && ticketCategory.People != ticketCategory.SeatCount && ticketCategory.People}
            ></EventSummary_TicketSalesRow>,
          );
        } else {
          const rows = group
            .select((ticketCategory) => {
              return (
                <EventSummary_TicketSalesRow
                  key={'category_row_' + eventDateSummary.EventDateId + '_' + ticketCategory.Id}
                  title={ticketCategory.Name}
                  titleColour={ticketCategory.Colour}
                  percentage={null}
                  amount={ticketCategory.SeatCount}
                  total={ticketCategory.PriceAsString}
                  people={ticketCategory.People > 1 && ticketCategory.People != ticketCategory.SeatCount && ticketCategory.People}
                ></EventSummary_TicketSalesRow>
              );
            })
            .toArray();

          children.push(
            <EventSummary_TicketSalesRow
              child={rows}
              key={'category_seatcategory_row_' + eventDateSummary.EventDateId + '_' + seatCategory.Id}
              title={seatCategory.Name}
              titleColour={seatCategory.Colour}
              subtitle={''}
              percentage={null}
              amount={group.sum((g) => g.SeatCount) + '/' + seatCategory.SeatCount}
              total={CurrencyHelper.formatCurrency(
                props.currency,
                group.sum((g) => g.PriceAsInt),
              )}
            ></EventSummary_TicketSalesRow>,
          );
        }
      });

    linq
      .from(eventDateSummary.TicketsByCategory)
      .where((tc) => !tc.SeatCategory)
      .toArray()
      .forEach((ticketCategory) => {
        children.push(
          <EventSummary_TicketSalesRow
            key={'category_row_' + eventDateSummary.EventDateId + '_' + ticketCategory.Id}
            title={ticketCategory.Name}
            titleColour={ticketCategory.Colour}
            subtitle={ticketCategory.SeatCategory ? ticketCategory.SeatCategory.Name : ''}
            subtitleColour={ticketCategory.SeatCategory ? ticketCategory.SeatCategory.Colour : ''}
            percentage={null}
            wording={'Tickets'}
            amount={ticketCategory.SeatCount}
            total={ticketCategory.PriceAsString}
            people={ticketCategory.People > 1 && ticketCategory.People != ticketCategory.SeatCount && ticketCategory.People}
          ></EventSummary_TicketSalesRow>,
        );
      });

    return (
      <EventSummary_TicketSalesRow
        key={'date_row_' + eventDateSummary.EventDateId}
        child={children.length == 0 ? null : children}
        title={timeOnly ? timeString : dateString + ' at ' + timeString}
        subtitle={''}
        percentage={percentage}
        amount={amount}
        total={CurrencyHelper.formatCurrency(props.currency, total)}
      ></EventSummary_TicketSalesRow>
    );
  };

  linq
    .from(props.eventDateSales)
    .groupBy((d) => moment(d.EventDateAsString).format('YYYY-MM-DD'))
    .orderBy((g) => moment(g.first().EventDateAsString).unix())
    .forEach((group) => {
      if (group.count() > 1) {
        const day = moment(group.first().EventDateAsString);

        const totals = linq.from(group.toArray().map((g) => getEventDateTotals(g)));

        rows.push(
          <EventSummary_TicketSalesRow
            key={'date_row_' + day.format('YYYY-MM-DD')}
            child={group
              .orderBy((g) => moment(g.EventDateAsString).unix())
              .toArray()
              .map((g) => getDateTimeNode(g, true))}
            title={day.format(dayFormat)}
            subtitle={''}
            percentage={totals.sum((p) => p.percentage) / totals.count()}
            amount={`${totals.sum((t) => t.soldTickets)}/${totals.sum((t) => t.totalTickets)}`}
            total={CurrencyHelper.formatCurrency(
              props.currency,
              totals.sum((p) => p.total),
            )}
          ></EventSummary_TicketSalesRow>,
        );
      } else {
        rows.push(getDateTimeNode(group.first()));
      }
    });

  return (
    <div style={{ borderRadius: '6px', overflow: 'none' }}>
      {
        <EventSummary_TicketSalesRow
          key="event_row"
          child={rows}
          title={'Total'}
          subtitle={''}
          percentage={(totalSoldTickets / totalTickets) * 100.0}
          amount={totalSoldTickets + '/' + totalTickets}
          total={CurrencyHelper.formatCurrency(
            props.currency,
            ticketsEnum.sum((t) => {
              return (t as any).PriceAsInt;
            }) - totalDiscountsAsInt,
          )}
        ></EventSummary_TicketSalesRow>
      }
    </div>
  );
};

export default EventSummary_TicketSales;
