import React from 'react';
import { createPortal } from 'react-dom';
import { ITheme } from '../interfaces/ITheme';
import SVGSeatyFace from '../svg/SVGSeatyFace';
import usePortal from './usePortal';

interface IProps {
  inline?: boolean;
  theme?: ITheme;
}

const Loader: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const target = usePortal('loaders-are-us');

  const loader = (
    <div className={`loader ${props.inline ? ' inline' : ''}`} style={props.theme && { background: props.theme.MainColour }}>
      <SVGSeatyFace />
      <div className="spinner-container">
        <div className="spinner">
          <span className="notch" style={props.theme && { background: props.theme.MainColour }}></span>
          <span className="dot"></span>
        </div>
      </div>
      <label>{props.children ? props.children : 'We appreciate your patience.'}</label>
    </div>
  );

  if (props.inline) {
    return loader;
  } else {
    return createPortal(loader, target);
  }
};

export default Loader;
