import './FAQ.scss';

import React from 'react';
import { Helmet } from 'react-helmet-async';

import SideBar from './Sidebar';

interface IProps {}

const FAQPage: React.FC<IProps> = (props) => {
  return (
    <>
      <Helmet>
        <title>Seaty - FAQ</title>
        <meta name="description" content={`Seaty.co.uk FAQ.`} />
      </Helmet>
      <div className="docs faq-page">
        <SideBar />

        <div className="content">
          <article>
            <header>
              <h1>Frequantly Asked Questions</h1>
            </header>

            <div className="row">
              <div className="col col-sm-12">
                <div>
                  <h3>Want some Help?</h3>

                  <p>
                    Hello! 👋 Welcome to Seaty, your partner when organising your next event's ticket sales. We're all about keeping it simple, enjoyable, and stress-free! 😊
                    <br />
                    <br />
                    Allow us to guide you with some handy tips on launching your events with us, along with introducing you to some nifty tools to help along the way. 🚀
                    <br />
                    <br />
                    Need a chat? Don't hesitate to reach out! You can ping us an email anytime at <a href="mailto:support@seaty.co.uk">Support@Seaty.co.uk</a> 💌. We're here to help, so let's make
                    event management a breeze together! 💼🎉
                  </p>
                </div>
              </div>

              <div className="col col-sm-12">
                <div>
                  <h3>How do I sign up for Seaty?</h3>
                  <p>To make the most of Seaty, you'll first need to sign up! 😊</p>

                  <dl>
                    <dt>How do I join the Seaty family?</dt>
                    <dd>
                      To host an event with Seaty, you'll first need to join us by creating a user account. If you haven't done this yet, no worries! Simply click on the 'sign up' button, found on the
                      top right of most pages, and fill in the sign-up form. 👍
                    </dd>

                    <dt>What do I need to know about account verification?</dt>
                    <dd>
                      After signing up to Seaty, remember to verify your email address - this is a crucial step! Sometimes, verification emails might end up in your 'junk email', so don't forget to
                      check there too. 🕵️‍♀️
                    </dd>

                    <dt>Why is my email address important to Seaty?</dt>
                    <dd>
                      Your email address is super important to us, it's like your unique ID in the Seaty universe. All your orders, requests, and payments will be linked to your email address. So make
                      sure it's correct and checked regularly! 📧🔒
                    </dd>
                  </dl>
                </div>
              </div>
              {/* 
TODO: ADD TOURS */}

              <div className="col col-sm-12">
                <div>
                  <h3>Banking and Cashflow</h3>

                  <p>
                    There are a couple of different ways to accept card payments for your event, you can use the default way where Seaty will retain the event money until after the event finishes, or
                    you can make your own Stripe account and link it to Seaty with Stripe connect, that way you will receive the ticket money directly to your business bank account.
                  </p>

                  <h3>Standard Event</h3>

                  <dl>
                    <dt>How do I withdraw the money from my event?</dt>
                    <dd>
                      Shortly after your event ends, within 24 hours, your funds will become available to withdraw. You can do this from the event Banking accessible via
                      https://seaty.co.uk/Organisation/YourOrganisationTag/Event/YourEventTag/Banking. Press the Request Withdraw button to open up a dialog where you can enter your bank details.
                    </dd>

                    <dt>How long will it take for money to appear in my account?</dt>
                    <dd>The money will usually take 3-5 working days to appear in your account after a withdrawal. Our team will manually review each withdraw before it is made.</dd>

                    <dt>How do fees work?</dt>
                    <dd>
                      Fees are collected by Seaty for our service either by passing the fee on to the attendee or by absorbing it in the cost of tickets, you can decide which from the event editor.
                    </dd>
                  </dl>

                  <h3>Stripe Connect Events</h3>

                  <dl>
                    <dt>How do I set up Stripe Connect</dt>
                    <dd>
                      First set up your organisation and first event. Then go to Stripe.com and set up a Stripe account for your business. Next, get in touch with Seaty at Support@Seaty.co.uk and ask
                      to have Stripe Connect enabled for your organisation. If we approve we will enable Stripe connect. When we tell you to do so you can go to the Stripe section of your organisation
                      Edit page. You can then follow the on screen prompts to link your Stripe account to Seaty. Once completed all payments for orders will go to your Stripe account.
                    </dd>

                    <dt>How long will it take for money to appear?</dt>
                    <dd>Money for your tickets and orders should appear immediately via Stripe connect.</dd>

                    <dt>How do fees work with Stripe connect?</dt>
                    <dd>
                      An application fee will be taken from the amount of the order by Stripe and given to Seaty before the money for the order is added to your Stripe account. Stripe will then take
                      its own fees. Seaty will reduce its fee amount from the default amount to accommodate for the additional fees paid to Stripe so that the costs of using Stripe connect in this way
                      are similar to the default fee method. Please see our fees page for details on fee amounts.
                    </dd>

                    <dt>Are there any additional terms or things I need to think about when using Stripe Connect?</dt>
                    <dd>
                      The most important thing to remember when you use Stripe Connect is that you are agreeing to take the responsibility of refund customers when necessary. You should always keep a
                      retainer of money in your Stripe account to ensure orders can be refunded. If an event is cancelled, you are responsible for ensuring each customer is refunded in full and ensure
                      your Stripe account has the funds to cover this.
                    </dd>
                  </dl>
                </div>
              </div>

              <div className="col col-sm-12">
                <div>
                  <h3>What are the basics of Seaty?</h3>

                  <p>At its core, Seaty is your go-to platform managing your event's ticketing. 🎫</p>

                  <dl>
                    <dt>How do I manage event dates?</dt>
                    <dd>Each of your events can have unlimited event dates and times, you can use our calendar tools to quickly add dates and times to your event, whatever form it might take 📅</dd>

                    <dt>How can I host seated events?</dt>
                    <dd>
                      Hosting an event with a seating plan and seat categories? Our platform makes it a breeze. Visitors can handpick their seats using the seating plan we help you create. Once
                      they've made their choices, they proceed through our straightforward checkout process. 😊
                    </dd>

                    <dt>What are the options for general admission events?</dt>
                    <dd>
                      Planning an event with set ticket quantities? We've got you covered. Our platform allows your guests to select the number of tickets they want to buy, all in one place. And like
                      always, they finish by navigating through our user-friendly checkout process. 😎
                    </dd>
                  </dl>
                </div>
              </div>

              <div className="col col-sm-12">
                <div>
                  <h3>How do I set up my organization?</h3>

                  <p>
                    Before you dive into event planning, it's important to set up your organisation first. Think of your organisation as a big umbrella that can host many events. ☂️💼 Not only does it
                    help group your events together, but it's also a brilliant way to share information about your company across all your events. It's like your very own command centre, keeping
                    everything neat, organised, and efficient. 🌐💡 So, let's start with creating your organisation. It's your first step towards many successful events! 🚀🎉
                  </p>

                  <dl>
                    <dt>What are user admins?</dt>
                    <dd>
                      You can add as many user admins as you wish who'll have full control over your event. But don't worry, even if they're removed from this list, event creators will always remain
                      admins of the event. 🛡️
                    </dd>
                  </dl>
                </div>
              </div>

              <div className="col col-sm-12">
                <div>
                  <h3>What are events and how do I start one?</h3>

                  <p>
                    An event can be anything requiring ticket on a specific date and time! Be it a grand production, a beautiful wedding, a focused business seminar, or any other creative concept you
                    have in mind. 🌟
                  </p>

                  <dl>
                    <dt>How do I create an event?</dt>
                    <dd>
                      After you've signed into Seaty, look for the 'Create Event' option in the top menu bar, or within the user options menu. Clicking this will launch our handy event wizard, guiding
                      you to create a basic event outline. 🎈👈 Once that's done, it's time to add some colour! Head over to the full event editor and work your way through the various sections to
                      truly bring your event to life. Each section is complemented with helpful information to assist you, and many of the fields feature an info button that you can click for more
                      details. It's like having a little helper, ready to guide you every step of the way! 🧚🖊️
                    </dd>

                    <dt>How do I add event details?</dt>
                    <dd>
                      Now, it's time to share more about your event. Fill in all the juicy details you have at hand. Remember, most of these particulars will be visible to your guests when they visit
                      Seaty. Some information is required to get your event off the ground. 📝
                    </dd>

                    <dt>What is an Event Tag?</dt>
                    <dd>
                      As you create a new event, you'll need to come up with a unique event tag. This special identifier must be one-of-a-kind and not the same as any other event in the Seaty
                      database. These tags are brilliant - they offer a direct way to market your event page. So, get creative with Seaty.co.uk/YourEventTagHere. 👍
                    </dd>

                    <dt>How do I set event terms and conditions?</dt>
                    <dd>
                      This is where you can lay down any terms and conditions pertinent to your event. They'll be made visible to anyone booking seats via Seaty. We make sure it's clear these are your
                      own terms and conditions, not those of Seaty. ✅
                    </dd>

                    <dt>How do I save or edit an existing event?</dt>
                    <dd>
                      Once you're happy with all the details, click the 'Save' button at the top of the page. Remember, no event info will be stored until this button is clicked and the save is
                      confirmed by Seaty. 😊
                    </dd>

                    <dd>
                      Need to tweak an existing event? Just go to 'My Events' from the main menu or user options menu. You can then look through your different events and go to the edit option. After
                      you've made your changes, don't forget to hit the 'Save' button at the top of the page. 💡
                    </dd>

                    <dt>What are categories and event dates?</dt>
                    <dd>We'll discuss more about these in another section. See you there! 👋</dd>
                  </dl>
                </div>
              </div>

              <div className="col col-sm-12">
                <div>
                  <h3>How do I create seating plans?</h3>

                  <p>The first thing you are going to need is somewhere for people to sit. This can be accomplished by creating a seating plan.</p>

                  <dl>
                    <dt>How do I start building a seating plan?</dt>
                    <dd>
                      After signing into Seaty, click on 'Create Event' in the top menu bar, or in the user options menu, then go through the initial wizard and make sure you select the seated event
                      option. You can then go to the full event editor and the 'Seating Plan' section to start building. ✏️
                    </dd>

                    <dt>How do I choose a plan size and create my layout?</dt>
                    <dd>
                      Next, decide how many rows and columns you want using the column and row options. Don't worry about nailing it on the first try - you can always adjust these values later. This
                      will give you a grid of seats to start with. 🚁
                    </dd>

                    <dd>
                      You'll now see a grid of seat placeholders. Click on any placeholder to select it and make it into a seat, or choose the select tool from the right menu and click and drag to
                      select multiple seats to draw your seating plan. 👆
                    </dd>

                    <dt>How do I add objects and save my seating plan?</dt>
                    <dd>
                      You can open the dropdown on the top menu bar and change from a seat to a number of other objects, such as a stage, table, etc. You can then add these objects to your plan much
                      the same way as your seats.
                    </dd>

                    <dt>How do I save my plan?</dt>
                    <dd>
                      Once you're satisfied with your fantastic seating plan, click 'save' at the top of the page. Your plan is now safely stored in the Seaty database, ready for when you need it. Do
                      remember to hit 'save' to make any changes official. 💾
                    </dd>

                    <dt>How do I label my seats?</dt>
                    <dd>
                      Each seat in your plan must have a unique combination of seat category, row or group name, and seat number or name. You can open the design drop down at the top menu bar and
                      select label to enter labelling mode. Once there you can select your seats and either manually add a group and name, or use our automated labelling tools in the menu bar. 🏷️
                    </dd>

                    <dt>What are seat group names?</dt>
                    <dd>
                      Here at Seaty, we call the first part of a seat's label the group name because it may not always represent a row, a group could be anything from a row of seats, to a table of
                      seats, etc.
                    </dd>

                    <dt>What are seat names?</dt>
                    <dd>
                      Each seat must have a unique full name, even if they share the same group name. Seats can only share the same group and name combination when they belong to different seat
                      categories. To make seat numbering easier, we've added an ascending and descending auto-number button. Just select any number of seats and click either button to label the seats
                      by number. Enter a number in the seat name text box and then press any auto number button, the seats will be labelled from that number onwards.
                    </dd>
                  </dl>
                </div>
              </div>

              <div className="col col-sm-12">
                <div>
                  <h3>Seat Categories</h3>

                  <p>Seat categories are your best friends when organising seats! When a seat is taken, it's always linked to a category. 🪑</p>

                  <dl>
                    <dt>What are seat categories and how do I create them?</dt>
                    <dd>
                      Want to create a category? Fantastic! They can be added to events in the event editor page. Not sure how to get there? Just check out the tips in the events section above. Every
                      new event comes with a default category that can be changed or removed. Once in the event editor's seating plan category section, click the 'Add' category button, give your
                      category a name, and hit confirm. Voila, it's added to your event seating plan! ✅
                    </dd>

                    <dt>How do I set category colors and prices?</dt>
                    <dd>
                      Each category needs a colour, helping you to quickly identify occupied seats in your seating plan. You can select a colour in the event category row within the event editor.
                      Don't forget to save your changes! 🌈
                    </dd>

                    <dd>
                      To set a price for a seat category, first you have to add a ticket in the Seated Tickets section. You will then see each seat category for that ticket with options next to it. A
                      category starts as FREE by default (price = 0). You can adjust this to represent any amount for your tickets. At Seaty, we're based in the UK so we usually work with GBP, but you
                      can use any currency as long as you let your guests know. Set the price, hit save, and you're done. Remember, setting the value back to 0 will always show as FREE. Prices can be
                      updated at any time, even during live events, and won't affect any existing orders - they'll stay at the price when the order was placed. 💷
                    </dd>

                    <dt>What are hidden categories?</dt>
                    <dd>
                      Want to assign seats to a category but keep it hidden from attendees? Typical use cases include complimentary seats. Just tick the 'hidden' toggle in the ticket category row and
                      save your changes. Only admins will be able to process orders for this category and no requests can be made. You can set any price you like and it will behave normally as an
                      admin. 👀
                    </dd>
                  </dl>
                </div>
              </div>

              <div className="col col-sm-12">
                <div>
                  <h3>Event Dates</h3>

                  <p>Event event needs dates and times in order to function. Everything revolves around them and all tickets are booked against a specific event date and time.</p>

                  <dl>
                    <dt>How do I create event dates?</dt>
                    <dd>
                      You will initially add an event date in the create event wizard. In the full event editor you can go to the date section from the main menu. Here you can choose to view and
                      manage your events in either a list or calendar mode. There are various tools available to help you quickly add, modify or remove event dates. You cannot remove an event date
                      that has orders against it but you can suspend it and make it invisible to attendees.
                    </dd>
                  </dl>
                </div>
              </div>

              <div className="col col-sm-12">
                <div>
                  <h3>Event Landing Pages</h3>

                  <p>
                    Consider your event landing page as the cosy home for your event on Seaty! It's the perfect spot for your guests to explore event details and choose their preferred dates. Simply
                    direct them to seaty.co.uk/YourEventTag. 🏠
                  </p>

                  <dl>
                    <dt>How do I access the event page and what can I do there?</dt>
                    <dd>
                      Once your event is created and dates are added, your event page is just a click away at Seaty.co.uk/YourEventTag. Additionally, as an admin you can access it at any time by
                      clicking the 'Landing' option from the event admin menu. 🎯
                    </dd>

                    <dd>On your event page, guests and users can soak up all relevant event information. They can check out each of your event dates, It's your event, at a glance! 👀</dd>
                  </dl>
                </div>
              </div>

              <div className="col col-sm-12">
                <div>
                  <h3>Event Date Pages</h3>

                  <p>Event date pages showcase each date and time of your event. This is where users and administrators will book tickets to your event.</p>

                  <dl>
                    <dt>How do I access the event date page and what can I do there?</dt>
                    <dd>
                      Event date pages can be reached by clicking on an event date from the list on any event page, or by sharing a direct link to a specific event date. Easy peasy! You can move
                      through your event dates once on one by using the left and right arrows next to the date at the top of the page 🖱️
                    </dd>

                    <dt>What can the event administrator do there?</dt>
                    <dd>As the administrator of an event, the event date page is your command centre. From here, you can select seats and place a direct order. 📋</dd>

                    <dt>What can a guest or regular user do there?</dt>
                    <dd>
                      Any guest or user can dive into the interactive seating chart on the event date page. They can pick out seats and make requests. Plus, they can easily see which tickets are
                      available and which ones are not. Keep in mind, only users can request seats, never guests. 🎟️
                    </dd>
                  </dl>
                </div>
              </div>

              <div className="col col-sm-12">
                <div>
                  <h3>Seat Requests</h3>

                  <p>
                    You can enable seat requests for your event in the event editor. Requests are disabled by default. Seat requests are made by Seaty users when they view event date pages. Think of
                    it as your virtual hand-raise to reserve a seat! 🙋‍♂️
                  </p>

                  <dl>
                    <dt>What are seat requests and how do I make them?</dt>
                    <dd>
                      To request some seats for an event, simply visit the event page and select a date to land on the event date page. From here, select the seats you fancy in the seating chart that
                      are still available. Once you've selected your seats, click on order, then choose request seats from the list of available options, requesting is only available for events that
                      have it enabled. Once you're happy, You can finalise the request and send it off to the event team. You'll receive an email outlining the outcome of your request as soon as it is
                      reviewed by an event administrator. 📧
                    </dd>

                    <dt>How do I know if my request will be accepted or refused?</dt>
                    <dd>
                      Well, you don't. Always be mindful that the event administrator will likely be expecting you to pay for the seats. So, if it's your first time requesting seats for an event and
                      you're not sure the event administrator knows you, drop them a line or include a note in your request. It never hurts to add a little context! 📝 The event administrator reserves
                      the right to refuse your request at any time. Remember, you're not paying for anything through Seaty by requesting a seat, so there's no obligation for your request to be
                      accepted. By making a request, you're agreeing to pay the event organisers the amount due for any order they accept from your request. Rest assured, Seaty will never chase monies
                      from any party for any seat requests. 💷
                    </dd>

                    <dd>You'll receive an email letting you know whether your order has been placed or if your request was refused. Keep an eye on your inbox! 💌</dd>

                    <dt>How do I accept or refuse seat requests for my event?</dt>
                    <dd>
                      You can navigate to the request review page by clicking on 'My Events' at the top of the page, then 'Requests'. If you're an admin of the event and logged in, you can also get to
                      the requests page directly from the event or event date pages. 🖥️ On the requests page, you'll see all requests in the order they were made. You can accept or refuse a request by
                      clicking the appropriate buttons. If you need to cancel a request, please provide a valid reason as the user will want to know what they can do to have a better shot at
                      acceptance next time around. Remember to review seat requests regularly, as orders and requests cannot be placed on requested seats. ☑️🚫
                    </dd>
                  </dl>
                </div>
              </div>

              <div className="col col-sm-12">
                <div>
                  <h3>Seat orders</h3>

                  <p>Seat orders are placed either by an attendee booking and paying by card, directly by event administrators or generated by accepting requested seats.</p>

                  <dl>
                    <dt>What are seat orders and how do I make them?</dt>
                    <dd>
                      The main way to make an order as an attendee is to choose the tickets you want, and then buy them by credit or debit card. As an admin, accepting a seat request will
                      automatically generate an order representing those requested seats. To place an order directly as an event administrator, navigate to the event and then to the event date page.
                      Choose the seats you wish to order, then click the "Order" button (this will only be visible to you if you're set up as an admin of the event). Next, select the categories and
                      prices for the seats in the list that will appear for this order. Type in the email address of the person for whom this order is intended. It's crucial to get this right, as this
                      will be how the order is tracked and where the order confirmation email is sent. Keep in mind that using the requests system is a more secure way of placing orders since all
                      emails have been verified by the user making the request. Once you click on the "Place Order" button, the entered email address will receive the order, and the seats in the
                      seating chart will update to show they're occupied.
                    </dd>

                    <dt>How do I cancel an order as an administrator or user?</dt>
                    <dd>
                      As an event administrator, to cancel an order, go to the event page and then to the event date page. Select the occupied seats for which you wish to cancel the order, then click
                      the "Cancel Order" button. A pop-up will appear showing all the seat orders that will be cancelled. Here, you need to enter a reason for cancelling the order. Click on the
                      "Cancel Order" button to confirm. Each user will be emailed when their order is cancelled, showing them what and why it was cancelled. You can cancel individual seats within an
                      order. However, cancelling a seat will not cancel the whole order unless all seats in that order are cancelled.
                    </dd>

                    <dt>How do I cancel an order as a user?</dt>
                    <dd>
                      To cancel an order, contact the event organizer or administrator to cancel the seats for you. Remember, as a user, you don't have the permission to cancel the order yourself.
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default FAQPage;
