import linq from 'linq';
import moment from 'moment';
import { FunctionComponent } from 'react';
import CurrencyHelper from '../../../../helpers/CurrencyHelper';
import { IEvent } from '../../../../interfaces/IEvent';

import EventSummary_TicketSalesRow from './EventSummary_TicketSalesRow';

interface IEventDateSale {
  EventDateId: number;
  EventDateAsString: string;
  SoldTickets: any;
  Discounts: any[];
  PriceAsInt: number;
  TicketsByCategory: any;
}

export interface IProps {
  currency: string;
  eventDateSales: IEventDateSale;
  event: IEvent;
}

const EventDateSummary_TicketSales: FunctionComponent<IProps> = (props) => {
  var event = props.event;

  var rows = [];

  const hasSeatingPlans = event.UseSeatingPlan && event.SeatingPlans && event.SeatingPlans.length > 0;

  var totalDiscountsAsInt = 0;
  const dayFormat = 'ddd Do MMM YY';

  const getEventDateTotals = (eventDateSummary: IEventDateSale) => {
    const eventDate: any = linq.from(event.Dates).firstOrDefault((ed) => ed.Id == eventDateSummary.EventDateId);

    const categoryGroupsInDate = linq.from(event.UnallocatedCategoryGroups).where((g: any) => linq.from(g.Categories).any((c: any) => eventDate.GATicketCategoryIds.includes(c.Id)));
    const totalGeneralAdmissionTickets = categoryGroupsInDate.sum((c: any) => c.Quantity);

    var totalTicketsForEventDate = (hasSeatingPlans ? linq.from(event.SeatingPlans).sum((s) => s.SeatCount) : 0) + totalGeneralAdmissionTickets;
    var percentage = eventDateSummary.SoldTickets > totalTicketsForEventDate ? 100.0 : (eventDateSummary.SoldTickets / totalTicketsForEventDate) * 100.0;
    var amount = eventDateSummary.SoldTickets + '/' + totalTicketsForEventDate;
    var totalDiscounts = linq.from(eventDateSummary.Discounts).sum((t) => {
      return (t as any).Amount;
    });

    totalDiscountsAsInt += totalDiscounts;

    return {
      total: eventDateSummary.PriceAsInt - totalDiscounts,
      amount,
      soldTickets: eventDateSummary.SoldTickets,
      totalTickets: totalTicketsForEventDate,
      percentage,
    };
  };

  const getDateTimeNode = (eventDateSummary: IEventDateSale, timeOnly: boolean = false) => {
    var dateString = moment(eventDateSummary.EventDateAsString).format(dayFormat);
    var timeString = moment(eventDateSummary.EventDateAsString).format('h:mma');

    var children = [];
    const { total, amount, percentage } = getEventDateTotals(eventDateSummary);

    eventDateSummary.Discounts.forEach((discount) => {
      children.push(
        <EventSummary_TicketSalesRow
          key={'category_discount_row_' + eventDateSummary.EventDateId + '_' + discount.Id}
          title={discount.Name}
          titleColour={'#41abbb'}
          subtitle={''}
          subtitleColour={'#41abbb'}
          percentage={null}
          amount={discount.Quantity}
          total={'-' + CurrencyHelper.formatCurrency(props.currency, discount.Amount)}
        ></EventSummary_TicketSalesRow>,
      );
    });

    eventDateSummary.TicketsByCategory.forEach((ticketCategory) => {
      children.push(
        <EventSummary_TicketSalesRow
          key={'category_row_' + eventDateSummary.EventDateId + '_' + ticketCategory.Id}
          title={ticketCategory.Name}
          titleColour={ticketCategory.Colour}
          subtitle={ticketCategory.SeatCategory.Name}
          subtitleColour={ticketCategory.SeatCategory.Colour}
          percentage={null}
          wording={'Tickets'}
          amount={ticketCategory.SeatCount}
          total={ticketCategory.PriceAsString}
          people={ticketCategory.People > 1 && ticketCategory.People != ticketCategory.SeatCount && ticketCategory.People}
        ></EventSummary_TicketSalesRow>,
      );
    });

    return (
      <EventSummary_TicketSalesRow
        key={'date_row_' + eventDateSummary.EventDateId}
        child={children.length == 0 ? null : children}
        title={timeOnly ? timeString : dateString + ' at ' + timeString}
        subtitle={''}
        percentage={percentage}
        amount={amount}
        total={CurrencyHelper.formatCurrency(props.currency, total)}
      ></EventSummary_TicketSalesRow>
    );
  };

  linq
    .from([props.eventDateSales])
    .groupBy((d) => moment(d.EventDateAsString).format('YYYY-MM-DD'))
    .orderBy((g) => moment(g.first().EventDateAsString).unix())
    .forEach((group) => {
      if (group.count() > 1) {
        const day = moment(group.first().EventDateAsString);

        const totals = linq.from(group.toArray().map((g) => getEventDateTotals(g)));

        rows.push(
          <EventSummary_TicketSalesRow
            key={'date_row_' + day.format('YYYY-MM-DD')}
            child={group
              .orderBy((g) => moment(g.EventDateAsString).unix())
              .toArray()
              .map((g) => getDateTimeNode(g, true))}
            title={day.format(dayFormat)}
            subtitle={''}
            percentage={totals.sum((p) => p.percentage) / totals.count()}
            amount={`${totals.sum((t) => t.soldTickets)}/${totals.sum((t) => t.totalTickets)}`}
            total={CurrencyHelper.formatCurrency(
              props.currency,
              totals.sum((p) => p.total),
            )}
          ></EventSummary_TicketSalesRow>,
        );
      } else {
        rows.push(getDateTimeNode(group.first()));
      }
    });

  return <div style={{ borderRadius: '6px', overflow: 'none' }}>{rows}</div>;
};

export default EventDateSummary_TicketSales;
