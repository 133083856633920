import React, { FunctionComponent, useState } from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  FacebookShareCount,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share';
import { IEvent } from '../../interfaces/IEvent';
import UserHelper from '../../helpers/UserHelper';
import './EventShare.scss';
import SVGScan from '../../svg/SVGScan';
import SVGQR from '../../svg/SVGQR';
import QRCode from 'qrcode.react';
import SVGCopy from '../../svg/SVGCopy';
import SVGTick from '../../svg/SVGTick';
import SVGLink from '../../svg/SVGLink';
import EnvironmentVariables from '../../EnvironmentVariables';

export interface IProps {
  event: IEvent;
}

const EventShare: FunctionComponent<IProps> = (props) => {
  const { event } = props;

  const [showQRCode, setShowQRCode] = useState(false);
  const [copied, setCopied] = useState(false);

  const url = `${EnvironmentVariables.HOME_URL}/${event.EventTag}${UserHelper.currentUser ? `?referral=${UserHelper.currentUser.Guid}` : ''}`;
  const title = `Tickets to ${event.EventTag}`;
  const size = 32;

  return (
    <div className="event-share-container">
      {UserHelper.currentUser && !UserHelper.currentUser.Guest && (
        <div style={{ marginBottom: '10px' }} className="text">
          Get credit for sales by sharing through these buttons!
        </div>
      )}

      <div className="event-share ">
        <button onClick={() => setShowQRCode(!showQRCode)} className="scan-button react-share__ShareButton">
          <SVGQR />
        </button>

        <button
          onClick={() => {
            setCopied(true);
            navigator.clipboard.writeText(url);

            window.setTimeout(() => {
              setCopied(false);
            }, 2000);
          }}
          className={`copy-button ${copied ? 'copy-button-copied' : ''}`}
        >
          {copied ? <SVGTick /> : <SVGLink />}
        </button>

        <FacebookShareButton url={url}>
          <FacebookIcon size={size} round />
        </FacebookShareButton>

        <FacebookMessengerShareButton url={url} appId="747095462055934">
          <FacebookMessengerIcon size={size} round />
        </FacebookMessengerShareButton>

        <TwitterShareButton url={url} title={title}>
          <XIcon size={size} round />
        </TwitterShareButton>

        <WhatsappShareButton url={url} title={title} separator=":: ">
          <WhatsappIcon size={size} round />
        </WhatsappShareButton>

        <LinkedinShareButton url={url}>
          <LinkedinIcon size={size} round />
        </LinkedinShareButton>

        <EmailShareButton url={url} subject={title} body="body">
          <EmailIcon size={size} round />
        </EmailShareButton>
      </div>
      {copied && <div className="event-share-message">Event link copied!</div>}
      {showQRCode && (
        <div style={{ marginTop: '30px' }}>
          <div>
            <QRCode value={url} width={75} height={75} />
          </div>
          {UserHelper.currentUser && !UserHelper.currentUser.Guest && (
            <div style={{ marginTop: '10px', marginBottom: '10px' }} className="text">
              You can share this QR code and we will give you referral credit for any sales for anyone that uses it and buys tickets to this event.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EventShare;
