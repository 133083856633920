import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import AdminApi from '../../api/AdminApi';
import GeneralApi from '../../api/GeneralApi';
import Block from '../../components/Block';
import BlockHeader from '../../components/BlockHeader';
import BlockInfo, { InfoType } from '../../components/BlockInfo';
import BlockTextBox from '../../components/BlockTextBox';
import Button from '../../components/Button';
import CheckBox from '../../components/CheckBox';
import Loader from '../../components/Loader';
import SpacerTable from '../../components/SpacerTable';
import { DiscountTypes } from '../../enums/DiscountTypes';
import { PaymentMethods } from '../../enums/PaymentMethods';
import { QuestionTypes } from '../../enums/QuestionTypes';
import CurrencyHelper from '../../helpers/CurrencyHelper';
import DateHelper from '../../helpers/DateHelper';
import OrderHelper from '../../helpers/OrderHelper';
import ThemeHelper from '../../helpers/ThemeHelper';
import { IDiscount } from '../../interfaces/IDiscount';
import { IEvent } from '../../interfaces/IEvent';
import { IEventDate } from '../../interfaces/IEventDate';
import { IOrder } from '../../interfaces/IOrder';
import { ISeatCategory } from '../../interfaces/ISeatCategory';
import { ITheme } from '../../interfaces/ITheme';
import { ITicket } from '../../interfaces/ITicket';
import { ITicketCategory } from '../../interfaces/ITicketCategory';
import { IUser } from '../../interfaces/IUser';
import { PaymentGateway } from '../../modals/OrderModal';
import { AdmissionTypes } from '../Editor/event/TicketSetupSection';
import './Order.scss';
import TicketSection from './Sections/TicketSection';
import linq from 'linq';

export interface IOrderConfig {
  user: IUser;
  event: IEvent;
  eventDate: IEventDate;
  tickets: Array<ITicket>;
}

interface IProps {
  theme: ITheme;
  orderConfig: IOrderConfig;
  onOrderCompleted: (orderId: number) => void;
  backClicked: () => void;
  seatCategories: Array<ISeatCategory>;
  ticketCategories: Array<ITicketCategory>;
  stripeConnectAccountId: string;
  isAdmin: boolean;
  isMember: boolean;
  lockTickets: (tickets) => Promise<any>;
  gateway: PaymentGateway;
}

const SwapComponent: FunctionComponent<IProps> = (props) => {
  const user = props.orderConfig.user;
  const event = props.orderConfig.event;
  const eventDate = props.orderConfig.eventDate;
  const isAdmin = props.isAdmin;

  const [tickets, setTickets] = useState(props.orderConfig.tickets);

  const [busy, setBusy] = useState<string>(null);

  const [orderError, setOrderError] = useState<string>('');

  const [swapOrderNumber, setSwapOrderNumber] = useState('');
  const [swapOrder, setSwapOrder] = useState<IOrder>(null);
  const [swapComplete, setSwapComplete] = useState<boolean>(false);

  const [showOrganisationTermsAndConditions, setShowOrganisationTermsAndConditions] = useState<boolean>(false);
  const [acceptedSeatyTerms, setAcceptedSeatyTerms] = useState<boolean>(false);

  const [activeDiscount, setActiveDiscount] = useState<IDiscount>(null);

  const summary = OrderHelper.getOrderSummary(
    false,
    tickets,
    event.AbsorbFee,
    event.CardFeeMinimum,
    event.CardFeePercentage,
    activeDiscount,
    event.HandlingFee,
    event.HandlingFeePercentage,
    event.Organisation.ServiceFee,
  );

  const swapSeats = () => {
    setOrderError(null);
    setBusy('Swapping tickets...');

    AdminApi.request('POST', '/api/SwapTickets', {
      ExistingOrderId: swapOrderNumber,
      NewDateId: eventDate.Id,
      seats: tickets,
    })
      .then(() => {
        setSwapComplete(true);
        setBusy(null);
      })
      .catch((message) => {
        setOrderError(message);
        setBusy(null);
      });
  };

  const loadSwappingOrder = () => {
    setOrderError(null);
    setBusy('Loading order...');
    GeneralApi.request('GET', `/api/Order?orderId=${swapOrderNumber}`).then((order) => {
      if (order.ErrorMessage) {
        setOrderError(order.ErrorMessage);
      } else {
        if (order.Seats && order.Seats.length > 0) {
          const orderTickets = linq.from(order.Seats).toArray();

          for (var i = 0; i < orderTickets.length; i++) {
            const orderTicket = orderTickets[i];

            if (tickets && tickets.length > i) {
              const ticket = tickets[i];

              if (ticket.TicketCategories && ticket.TicketCategories.length > 0) {
                const matchingTicketCategory = linq.from(ticket.TicketCategories).firstOrDefault((tc) => tc.Name.toLowerCase() == orderTicket.CategoryName.toLowerCase());
                if (matchingTicketCategory) {
                  ticket.TicketCategory = matchingTicketCategory;
                  ticket.TicketCategoryId = matchingTicketCategory.Id;
                }
              }

              setTickets([...tickets]);
            }
          }
        }

        setSwapOrder(order);
      }
      setBusy(null);
    });
  };

  const scrollToTop = () => {
    const element = document.querySelector('.seaty-modal');
    if (element) element.scrollTop = 0;
  };

  useEffect(() => {
    setOrderError(null);
    setAcceptedSeatyTerms(false);
    setShowOrganisationTermsAndConditions(false);
    setBusy(null);
    setActiveDiscount(null);

    window.setTimeout(() => {
      const modal = document.querySelector('.seaty-modal');
      const dropdown: HTMLDivElement = modal && modal.querySelector('.dropdown');
      if (dropdown) dropdown.focus();
    }, 200);
  }, [props.orderConfig]);

  const renderShortSummarySection = () => {
    const totalPriceFormatted = CurrencyHelper.formatCurrency(event.CurrencySymbol, summary.total);
    let handlingFee = 0;

    // if (wasOnlinePayment) {
    //   if (event.HandlingFee && event.HandlingFee > 0) {
    //     handlingFee += event.HandlingFee;
    //   }
    //   if (event.HandlingFeePercentage && event.HandlingFeePercentage > 0) {
    //     handlingFee += summary.total * (event.HandlingFeePercentage / 100.0);
    //   }
    // }

    return (
      <Block className="tickets-totals">
        <table>
          <tbody>
            {(activeDiscount != null || summary.allFees > 0) && (
              <tr>
                <td className="regular-text-color">
                  <strong>Sub Total</strong>
                </td>
                <td className="regular-text-color right">
                  <strong>{CurrencyHelper.formatCurrency(event.CurrencySymbol, summary.subTotal)}</strong>
                </td>
              </tr>
            )}
            {activeDiscount && (
              <tr>
                <td className="regular-text-color">
                  <strong>
                    {activeDiscount.Name}
                    {activeDiscount.DiscountType === DiscountTypes.FixedAmountOffOrder &&
                      activeDiscount.Criteria &&
                      activeDiscount.Criteria.length > 0 &&
                      activeDiscount.Stack &&
                      activeDiscount.Stacks > 1 &&
                      ` x ${activeDiscount.Stacks}`}
                  </strong>
                </td>
                <td className="regular-text-color right">
                  <strong>- {CurrencyHelper.formatCurrency(event.CurrencySymbol, activeDiscount.Amount * 100.0)}</strong>
                </td>
              </tr>
            )}
            {summary.allFees > 0 && (
              <tr>
                <td className="regular-text-color">
                  <strong>Fees</strong>
                </td>
                <td className="regular-text-color right">
                  <strong>{CurrencyHelper.formatCurrency(event.CurrencySymbol, summary.allFees)}</strong>
                </td>
              </tr>
            )}

            <tr>
              <td className="regular-text-color">
                <strong className="extra">Total</strong>
              </td>
              <td className="regular-text-color right">
                <strong className="extra">{totalPriceFormatted}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </Block>
    );
  };

  const renderErrorSection = () => {
    return (
      <div>
        <SpacerTable>
          <h1>Sorry, there has been a problem</h1>
          {orderError}
        </SpacerTable>

        <div className="spacer" />

        <SpacerTable>
          <Button
            onExecute={() => {
              setOrderError(null);
            }}
            className="confirm large"
            text="Dismiss"
          />
        </SpacerTable>
        <div className="spacer" />
      </div>
    );
  };

  const render = () => {
    const eventHasTerms = event.TermsAndConditions != null && event.TermsAndConditions.length > 0;

    let anyUnansweredQuestions = false;

    tickets.forEach((ticket) => {
      const questions = event.Questions
        ? event.Questions.filter((q) => q.Required && q.Criteria != null && q.Criteria.length > 0 && q.Criteria.filter((c) => c.Guid === ticket.TicketCategory.Guid).length > 0)
        : [];

      if (questions.length > 0) {
        questions.forEach((question) => {
          const existingAnswers = ticket.QuestionAnswers.filter((a) => a.QuestionId === question.Id);

          if (!existingAnswers || existingAnswers.length == 0) {
            anyUnansweredQuestions = true;
          } else {
            if (question.QuestionType === QuestionTypes.Text) {
              existingAnswers.forEach((existingAnswer) => {
                if (existingAnswer.Text == null || existingAnswer.Text.length < 4) {
                  anyUnansweredQuestions = true;
                }
              });
            }
          }
        });
      }
    });

    const ticketsSection = (
      <>
        <div className="spacer-x2" />

        <SpacerTable>
          <h1>New Tickets</h1>
        </SpacerTable>

        <div className="spacer" />

        <table className="blocks tickets">
          <tbody>
            <TicketSection
              isAdmin={isAdmin}
              confirmed={false}
              onSeatedTicketCategoryChange={(_tickets) => {
                setTickets([..._tickets]);
              }}
              currency={event.CurrencySymbol}
              tickets={tickets}
              event={event}
              seatCategories={props.seatCategories}
            />
            {renderShortSummarySection()}
          </tbody>
        </table>
      </>
    );

    return (
      <div>
        <SpacerTable>
          <h1>{event.Name}</h1>
          {moment.utc(eventDate.DateAsString).format('dddd Do MMMM YYYY') + ' at ' + moment.utc(eventDate.DateAsString).format('h:mma')}
          <div>
            {event.Venue.Name}, {event.Venue.Postcode}
          </div>
        </SpacerTable>

        <div className="spacer-x2" />
        <SpacerTable>
          <h1>Swap tickets details</h1>
        </SpacerTable>
        <div className="spacer" />
        <table className="blocks">
          <tbody>
            {!swapOrder ? (
              <BlockInfo type={InfoType.Info}>
                You can swap the seats of an existing order with the ones you have selected. Just enter the order number you are swapping from to continue. Remember that all new seats must be of the
                same price. You must also swap the same number of seats from the existing order.
              </BlockInfo>
            ) : (
              !swapComplete && (
                <BlockInfo type={InfoType.Info}>We will attempt to swap the seats inside this order with your newly selected seats. The value and quantity of tickets must be the same.</BlockInfo>
              )
            )}

            {!swapOrder && (
              <Block>
                <BlockHeader>What is the order number that you would like to swap the selected seats with?</BlockHeader>
                <BlockTextBox type="text" autoFocus={true} value={swapOrderNumber} onChange={(e) => setSwapOrderNumber(e)} />
              </Block>
            )}

            {swapOrder && (
              <>
                <Block>
                  <BlockHeader>Order Number</BlockHeader>
                  {swapOrder.Id}
                </Block>
                {swapOrder.AttendeeName && swapOrder.AttendeeName.length > 0 && (
                  <Block>
                    <BlockHeader>Attendee</BlockHeader>
                    {swapOrder.AttendeeName}
                    <br />
                    {swapOrder.Email}
                  </Block>
                )}
              </>
            )}
          </tbody>
        </table>

        {swapOrder && (
          <>
            <div className="spacer" />
            <table className="blocks tickets">
              <tbody>
                {swapOrder.Seats.map((ticket: ITicket) => {
                  if (ticket.SeatCategoryId) {
                    return (
                      <Block key={'ticket_' + ticket.Group + '_' + ticket.Name + '_SEATED_' + ticket.TicketCategoryId}>
                        <BlockHeader>
                          <td className="ticket-font" style={{ color: ticket.SeatCategoryColour }}>
                            {ticket.Group}
                            {ticket.Name}
                          </td>
                          <td
                            className="right"
                            style={{
                              color: ticket.SeatCategoryColour,
                            }}
                          >
                            {ticket.SeatCategoryName}
                          </td>
                        </BlockHeader>

                        <table>
                          <tbody>
                            <tr>
                              <td>{ticket.CategoryName}</td>
                              <td className="right">{ticket.PriceAsString}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Block>
                    );
                  } else {
                    return (
                      <Block key={'ticket_' + ticket.Group + '_' + ticket.Name + '_GENERALADMISSION_' + ticket.TicketCategoryId}>
                        <BlockHeader>
                          <td style={{ color: ticket.CategoryColour }}>
                            {ticket.CategoryName} {ticket.Group}
                            {ticket.Name}
                          </td>
                          <td style={{ color: ticket.CategoryColour }} className="right">
                            {ticket.PriceAsString}
                          </td>
                        </BlockHeader>
                      </Block>
                    );
                  }
                })}
                {swapOrder.Discounts &&
                  swapOrder.Discounts.length > 0 &&
                  swapOrder.Discounts.map((d) => (
                    <React.Fragment key={d.Id}>
                      <Block>
                        <BlockHeader>{d.Name}</BlockHeader>-{CurrencyHelper.formatCurrency(swapOrder.Currency, d.Amount)}
                      </Block>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>

            <div className="spacer" />
            <table className="blocks">
              <tbody>
                {swapOrder && (
                  <>
                    <Block>
                      <BlockHeader>Swapping from</BlockHeader>#{swapOrder.Event.EventTag} - {DateHelper.asDateAtTimeAmPm((swapOrder as any).EventDate.Date)}
                    </Block>
                    <Block>
                      <BlockHeader>Swapping to</BlockHeader>#{event.EventTag} - {DateHelper.asDateAtTimeAmPm(eventDate.DateAsString)}
                    </Block>
                  </>
                )}
              </tbody>
            </table>
          </>
        )}

        {!swapComplete && (
          <>
            {!swapOrder ? (
              <>
                <div className="spacer" />
                <SpacerTable>
                  <Button className="confirm large" onExecute={loadSwappingOrder} text={'Load Order'} />
                </SpacerTable>
              </>
            ) : (
              <>
                <div className="spacer" />
                <SpacerTable>
                  <Button
                    className="bad large"
                    onExecute={() => {
                      setSwapOrder(null);
                      setSwapOrderNumber('');
                    }}
                    text={'Clear order'}
                  />
                </SpacerTable>
              </>
            )}
          </>
        )}

        {ticketsSection}

        {!swapComplete && (
          <>
            <div className="spacer-x2" />

            <SpacerTable>
              <h1>The legal bit...</h1>
            </SpacerTable>

            <div className="spacer" />

            <table className="blocks">
              <tbody>
                {event.AdmissionType === AdmissionTypes.Exchange && (
                  <BlockInfo>All Seaty tickets for this event must be taken to the venue box office and exchanged prior to the event starting.</BlockInfo>
                )}

                {showOrganisationTermsAndConditions && (
                  <Block>
                    <BlockHeader>{event.Organisation.Name}</BlockHeader>
                    <BlockHeader>Terms and Conditions</BlockHeader>
                    {event.TermsAndConditions}
                  </Block>
                )}

                <CheckBox title="Terms and Conditions" rightText={'(Required)'} onBoxClick={() => setAcceptedSeatyTerms(!acceptedSeatyTerms)} key="terms_checkbox" checked={acceptedSeatyTerms}>
                  I have read and agree to the Seaty{' '}
                  <a style={{ color: 'blue' }} href="https://seaty.co.uk/Docs/TermsOfService">
                    Terms of Service
                  </a>
                  {eventHasTerms ? ', ' : ' and '}
                  <a style={{ color: 'blue' }} href="https://seaty.co.uk/Docs/Privacy">
                    Privacy Policy
                  </a>
                  {eventHasTerms ? (
                    <span>
                      {' '}
                      and the {event.Organisation.Name}{' '}
                      {showOrganisationTermsAndConditions ? (
                        <span>Terms & Conditions</span>
                      ) : (
                        <a
                          style={{ color: 'blue' }}
                          href="#!"
                          onClick={() => {
                            setShowOrganisationTermsAndConditions(true);
                          }}
                        >
                          Terms & Conditions
                        </a>
                      )}
                      .
                    </span>
                  ) : null}
                </CheckBox>
              </tbody>
            </table>
          </>
        )}

        <div className="spacer-x2" />

        {swapOrder && (
          <>
            {swapComplete ? (
              <>
                <table className="blocks">
                  <tbody>
                    <BlockInfo type={InfoType.Info}>Your tickets have been swapped successfully. Please close this dialog.</BlockInfo>
                  </tbody>
                </table>
                <div className="spacer" />
                <SpacerTable>
                  <Button
                    className="confirm large"
                    onExecute={() => {
                      setBusy('Re-sending order email....');

                      GeneralApi.request('GET', `/api/ResendOrder?orderId=${swapOrderNumber}`)
                        .then((result) => {
                          setBusy(null);
                        })
                        .catch((message) => {
                          setBusy(null);
                        });
                    }}
                    text={'Re-send order email'}
                  />
                  <Button className="large" onExecute={props.backClicked} text={'Finish & Close'} />
                </SpacerTable>
              </>
            ) : (
              <>
                <SpacerTable>
                  <Button disabled={!acceptedSeatyTerms} className="confirm large" onExecute={swapSeats} text={'Swap Tickets'} />
                </SpacerTable>
              </>
            )}
          </>
        )}
      </div>
    );
  };

  if (orderError && orderError.length > 0) {
    return <>{renderErrorSection()}</>;
  }

  return (
    event &&
    eventDate &&
    tickets && (
      <>
        {busy && <Loader theme={ThemeHelper.getEventTheme(event)}>{busy}</Loader>}
        {render()}
      </>
    )
  );
};

const Swap: FunctionComponent<IProps> = (props) => {
  return <SwapComponent {...props}></SwapComponent>;
};

export default Swap;
