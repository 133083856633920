import moment from 'moment';
import React from 'react';
import DateHelper from '../../helpers/DateHelper';
import { IEvent } from '../../interfaces/IEvent';
import { LazyImage } from '../LazyImage';
import './EventWidget.scss';

interface IProps {
  event: IEvent;
  onClick: () => void;
}

const EventWidget: React.FC<IProps> = (props: IProps) => {
  const { event } = props;

  if (!event) return null;
  const venueName = event.Venue ? event.Venue.Name : event.VenueName;

  return (
    <div className="event-widget" onClick={props.onClick}>
      <LazyImage src={event.ImageUrl} alt={`${event.Name} Event`} />
      <div className="event-info">
        <h1 className="event-name">{event.Name}</h1>
        <h2 className="event-dates">{DateHelper.fromTo(event.MinDate, event.MaxDate)}</h2>
        {!event.TourId && (!event.Organisation || venueName.trim().toLowerCase() != event.Organisation.Name.trim().toLowerCase()) && <h3 className="event-venue">{venueName}</h3>}
        {event.Organisation && <h3 className="event-organisation">{event.Organisation.Name}</h3>}
        <div className="ticket-rip bottom" />
      </div>
    </div>
  );
};

export default EventWidget;
