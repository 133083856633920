import moment from 'moment';
import { FunctionComponent, useState } from 'react';
import NumberHelper from '../../helpers/NumberHelper';
import { IEventDate } from '../../interfaces/IEventDate';

import { ITicketCategory } from '../../interfaces/ITicketCategory';
import SVGMinus from '../../svg/SVGMinus';
import SVGPlus from '../../svg/SVGPlus';
import GeneralAdmissionDropdown from './GeneralAdmissionDropdown';
interface IProps {
  soldOut: boolean;
  ticket: ITicketCategory;
  quantitySelectedChanged: (amount: number) => void;
  value: number;
  eventDate: IEventDate;
  maxQuantity: number;
}

const GeneralAdmissionTicket: FunctionComponent<IProps> = (props) => {
  var ticket = props.ticket;

  var dropdownItems = [];
  for (var i = 0; i <= props.maxQuantity; i++) {
    dropdownItems.push({
      value: i,
      description: i,
    });
  }

  return (
    <div className="general-ticket">
      <div className="general-ticket-quantity">
        {props.soldOut ? (
          <div className="quantity">
            <span style={{ display: 'block' }}>Sold out</span>
          </div>
        ) : (
          <GeneralAdmissionDropdown selectedItemChanged={(dropdown) => props.quantitySelectedChanged(dropdown.value)} selectedValue={props.value} items={dropdownItems} />
        )}
      </div>
      <div className="general-ticket-details">
        <div className="name">{ticket.Name}</div>

        <div className="price">
          <span className="class" style={{ background: ticket.Colour }}></span>
          <span>{ticket.PriceAsString}</span>
        </div>

        {ticket.Description && ticket.Description.length > 0 && <div className="description">{ticket.Description}</div>}
        {ticket.Hide && <div className="description">* Only event administrators can book this ticket</div>}
      </div>
      {/* {props.soldOut ? (
        <div className="quantity">
          <span style={{ display: 'block' }}>Sold Out</span>
        </div>
      ) : (
        <div className="quantity-select">
          <button
            className="collapse"
            onClick={() => {
              if (NumberHelper.isNumeric(props.value) && props.value > 0) {
                props.quantitySelectedChanged(props.value - 1);
              }
            }}
          >
            <SVGMinus />
          </button>
          <GeneralAdmissionDropdown selectedItemChanged={(dropdown) => props.quantitySelectedChanged(dropdown.value)} selectedValue={props.value} items={dropdownItems} />

          <button
            className="expand"
            onClick={() => {
              if (NumberHelper.isNumeric(props.value) && props.value + 1 <= props.maxQuantity) {
                props.quantitySelectedChanged(props.value + 1);
              }
            }}
          >
            <SVGPlus />
          </button>
        </div>
      )} */}
    </div>
  );
};

export default GeneralAdmissionTicket;
