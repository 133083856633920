import Cookies from 'js-cookie';
import linq from 'linq';
import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AdminApi from '../../../../api/AdminApi';
import Loader from '../../../../components/Loader';
import SVGHelper from '../../../../helpers/SVGHelper';
import { IEvent } from '../../../../interfaces/IEvent';
import EventAttendeeModal from '../../../../modals/AttendeeOverview/EventAttendeeModal';
import SVGMultiply from '../../../../svg/SVGMultiply';
import { AdminSection } from '../../AdminSections';
import AdminBalanceRow from './AdminBalanceRow';
import AdminBalancesChart from './AdminBalancesChart';
import { IOrganisation } from '../../../../interfaces/IOrganisation';
import SideMenu from '../../../../components/SideMenu';
import SVGOutlineMoney from '../../../../svg/outline/SVGOutlineMoney';
import SVGOutlineMembership from '../../../../svg/outline/SVGOutlineMembership';
import SVGOutlineMoneyHide from '../../../../svg/outline/SVGOutlineMoneyHide';
import SVGOutlinePersonnel from '../../../../svg/outline/SVGOutlinePersonnel';
import SVGOutlineEmail from '../../../../svg/outline/SVGOutlineEmail';
import SVGOutlineEmailHide from '../../../../svg/outline/SVGOutlineEmailHide';
import SVGOutlineChart from '../../../../svg/outline/SVGOutlineChart';
import SVGOutlineList from '../../../../svg/outline/SVGOutlineList';
import SVGOutlineReferral from '../../../../svg/outline/SVGOutlineReferral';
import SVGOutlineReferralHide from '../../../../svg/outline/SVGOutlineReferralHide';

enum SortBy {
  TicketCount = 1,
  TotalSales = 2,
  ReferralTicketCount = 3,
  ReferralTotalSales = 4,
  Balance = 5,
  Name = 6,
  Email = 7,
}

enum SortDirections {
  Ascending = 1,
  Decending = 2,
}

const getAndResetCookieInt = (name: string): number => {
  const value = Cookies.get(name);

  if (value != null) {
    Cookies.set(name, value, { expires: 5, path: '/' });
    return parseInt(value);
  }
  return 0;
};

const getAndResetCookieBool = (name: string): boolean => {
  const value = Cookies.get(name);

  if (value != null && value == 'true') {
    Cookies.set(name, 'true', { expires: 5, path: '/' });
    return true;
  } else {
    Cookies.set(name, 'false', { expires: 5, path: '/' });
    return false;
  }
};

export interface IProps {
  organisation: IOrganisation;
  event: IEvent;
}

const AdminBalances: FunctionComponent<IProps> = (props) => {
  const { event } = props;
  const eventTag = event.EventTag;
  const membershipsEnabled = event.Organisation.MembershipsEnabled;

  const [filter, setFilter] = useState('');
  const [sortDirection, setSortDirection] = useState(getAndResetCookieInt(eventTag + '_OrdersAndPaymentsSortDirection'));
  const [sortBy, setSortBy] = useState(getAndResetCookieInt(eventTag + '_OrdersAndPaymentsSortBy'));

  const [includeReferrals, _setIncludeReferrals] = useState(getAndResetCookieBool(eventTag + '_IncludeReferrals'));
  const setIncludeReferrals = (value) => {
    Cookies.set(eventTag + '_IncludeReferrals', value, { expires: 5, path: '/' });
    _setIncludeReferrals(value);
  };

  const [hideEmail, _setHideEmail] = useState(getAndResetCookieBool(eventTag + '_HideEmail'));
  const setHideEmail = (value) => {
    Cookies.set(eventTag + '_HideEmail', value, { expires: 5, path: '/' });
    _setHideEmail(value);
  };

  const [hideMoney, _setHideMoney] = useState(getAndResetCookieBool(eventTag + '_HideMoney'));
  const setHideMoney = (value) => {
    Cookies.set(eventTag + '_HideMoney', value, { expires: 5, path: '/' });
    _setHideMoney(value);
  };

  const [showOnlyMembers, _setShowOnlyMembers] = useState(getAndResetCookieBool(eventTag + '_ShowOnlyMembers'));
  const setShowOnlyMembers = (value) => {
    Cookies.set(eventTag + '_ShowOnlyMembers', value, { expires: 5, path: '/' });
    _setShowOnlyMembers(value);
  };

  const [chartView, _setChartView] = useState(getAndResetCookieBool(eventTag + '_ChartView'));
  const setChartView = (value) => {
    Cookies.set(eventTag + '_ChartView', value, { expires: 5, path: '/' });
    _setChartView(value);
  };

  const [ordersAndPayments, setOrdersAndPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [selectedEmail, setSelectedEmail] = useState(null);

  const getOrdersAndPayments = (callback) => {
    AdminApi.request('GET', `/api/OrdersAndPayments?eventTag=${eventTag}`)
      .then((response) => callback(response))
      .catch((error) => alert('Error: ' + error));
  };

  const toTitleCase = (str) => {
    if (!str) return '';

    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const getName = (rowData) => {
    var response = '';

    if (rowData.IsMember && rowData.MemberName != null && rowData.MemberName.length > 0) {
      response = rowData.MemberName;
    } else if (rowData.UserId != 0) {
      response = rowData.User;
    } else {
      response = rowData.AttendeeName;
    }

    return toTitleCase(response);
  };

  const loadOrdersAndPayments = (silent?: boolean) => {
    setLoading(!silent);

    getOrdersAndPayments((response) => {
      var event = response.Event;
      setLoading(false);
      setOrdersAndPayments(
        linq
          .from(response.OrdersAndPayments)
          .select((rowData: any) => {
            rowData.PurchaserName = getName(rowData);

            return rowData;
          })
          .toArray(),
      );
    });
  };

  useEffect(() => {
    loadOrdersAndPayments();
  }, []);

  if (!event) return null;

  const setSortCookie = (sortBy, sortDirection) => {
    Cookies.set(eventTag + '_OrdersAndPaymentsSortDirection', sortDirection, { expires: 5, path: '/' });
    Cookies.set(eventTag + '_OrdersAndPaymentsSortBy', sortBy, { expires: 5, path: '/' });
  };

  const handleTicketsHeaderClick = () => {
    var d = SortDirections.Decending;
    if (sortBy == SortBy.TicketCount) {
      d = sortDirection == SortDirections.Ascending ? SortDirections.Decending : SortDirections.Ascending;
    }

    setSortBy(SortBy.TicketCount);
    setSortDirection(d);
    setSortCookie(SortBy.TicketCount, d);
  };

  const handleTotalHeaderClick = () => {
    var d = SortDirections.Decending;
    if (sortBy == SortBy.TotalSales) {
      d = sortDirection == SortDirections.Ascending ? SortDirections.Decending : SortDirections.Ascending;
    }
    setSortBy(SortBy.TotalSales);
    setSortDirection(d);
    setSortCookie(SortBy.TotalSales, d);
  };

  const handleBalanceHeaderClick = () => {
    var d = SortDirections.Decending;
    if (sortBy == SortBy.Balance) {
      d = sortDirection == SortDirections.Ascending ? SortDirections.Decending : SortDirections.Ascending;
    }
    setSortBy(SortBy.Balance);
    setSortDirection(d);
    setSortCookie(SortBy.Balance, d);
  };

  const handleNameHeaderClick = () => {
    var d = SortDirections.Ascending;
    if (sortBy == SortBy.Name) {
      d = sortDirection == SortDirections.Ascending ? SortDirections.Decending : SortDirections.Ascending;
    }

    setSortBy(SortBy.Name);
    setSortDirection(d);
    setSortCookie(SortBy.Name, d);
  };

  const handleEmailHeaderClick = () => {
    var d = SortDirections.Ascending;
    if (sortBy == SortBy.Email) {
      d = sortDirection == SortDirections.Ascending ? SortDirections.Decending : SortDirections.Ascending;
    }

    setSortBy(SortBy.Email);
    setSortDirection(d);
    setSortCookie(SortBy.Email, d);
  };

  const handleRowClick = (rowData) => {
    setSelectedEmail(rowData.Email);
  };

  const overviewRefreshed = (overview) => {
    var row = linq
      .from(ordersAndPayments)
      .where(function (o) {
        return o.Email == overview.Email;
      })
      .firstOrDefault();

    row.OwedAsInt = overview.TotalOwedAsInt;
    row.Owed = overview.TotalOwedAsString;

    row.PriceAsInt = overview.TotalOrdersPriceAsInt;
    row.Price = overview.TotalOrdersPriceAsString;

    row.PricePlusReferralPriceAsInt = overview.TotalOrdersAndReferralsPriceAsString;

    row.ReferralPriceAsInt = overview.TotalReferralsPriceAsInt;
    row.ReferralPrice = overview.TotalReferralsPriceAsString;

    row.Tickets = overview.TotalTickets;
    row.ReferralOrder = overview.TotalReferralTickets;

    row.IsMember = overview.IsMember;

    setOrdersAndPayments(ordersAndPayments);
  };

  const onFilterKeyPress = (e: any) => {
    if (e.key == 'Enter') {
      setFilter(query);
    }
  };

  const clearFilterClicked = () => {
    setFilter('');
    setQuery('');
  };

  const handleSearchChanged = (e) => {
    setQuery(e.target.value);
  };

  var rows = [];
  var sortedData = ordersAndPayments;

  if (showOnlyMembers) {
    sortedData = linq
      .from(sortedData)
      .where((x) => {
        return x.IsMember;
      })
      .toArray();
  }

  if (sortBy == SortBy.TicketCount) {
    if (sortDirection == SortDirections.Ascending) {
      sortedData = linq
        .from(sortedData)
        .orderBy((rowData) => (includeReferrals ? rowData.Tickets + rowData.ReferralsReceived - rowData.ReferralsGiven : rowData.Tickets))
        .thenBy((x) => getName(x))
        .toArray();
    } else {
      sortedData = linq
        .from(sortedData)
        .orderByDescending((rowData) => (includeReferrals ? rowData.Tickets + rowData.ReferralsReceived - rowData.ReferralsGiven : rowData.Tickets))
        .thenBy((x) => getName(x))
        .toArray();
    }
  } else if (sortBy == SortBy.TotalSales) {
    if (sortDirection == SortDirections.Ascending) {
      sortedData = linq
        .from(sortedData)
        .orderBy((rowData) => (includeReferrals ? rowData.PriceAsInt + rowData.ReferralsReceivedPriceAsInt - rowData.ReferralsGivenPriceAsInt : rowData.PriceAsInt))
        .thenBy((x) => getName(x))
        .toArray();
    } else {
      sortedData = linq
        .from(sortedData)
        .orderByDescending((rowData) => (includeReferrals ? rowData.PriceAsInt + rowData.ReferralsReceivedPriceAsInt - rowData.ReferralsGivenPriceAsInt : rowData.PriceAsInt))
        .thenBy((x) => getName(x))
        .toArray();
    }
  } else if (sortBy == SortBy.Balance) {
    if (sortDirection == SortDirections.Ascending) {
      sortedData = linq
        .from(sortedData)
        .orderBy((x) => x.OwedAsInt)
        .thenBy((x) => getName(x))
        .toArray();
    } else {
      sortedData = linq
        .from(sortedData)
        .orderByDescending((x) => x.OwedAsInt)
        .thenBy((x) => getName(x))
        .toArray();
    }
  } else if (sortBy == SortBy.Name) {
    if (sortDirection == SortDirections.Ascending) {
      sortedData = linq
        .from(sortedData)
        .orderBy((x) => getName(x))
        .thenBy((x) => x.Email)
        .toArray();
    } else {
      sortedData = linq
        .from(sortedData)
        .orderByDescending((x) => getName(x))
        .thenBy((x) => x.Email)
        .toArray();
    }
  } else if (sortBy == SortBy.Email) {
    if (sortDirection == SortDirections.Ascending) {
      sortedData = linq
        .from(sortedData)
        .orderBy((x) => x.Email)
        .thenBy((x) => getName(x))
        .toArray();
    } else {
      sortedData = linq
        .from(sortedData)
        .orderByDescending((x) => x.Email)
        .thenBy((x) => getName(x))
        .toArray();
    }
  }

  const filteredData = [];

  sortedData.forEach((rowData, index) => {
    var include = true;
    if (filter != null && filter.length > 0) {
      include = false;

      if (
        rowData.Email.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
        rowData.User.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
        rowData.PurchaserName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
      ) {
        include = true;
      }
    }

    if (include) {
      filteredData.push(rowData);

      var rowId = 'orderandpaymentsrow_' + rowData.Email;

      if (rowData.MemberId != null) {
        rowId += '_' + rowData.MemberId;
      }

      rowId += '_' + index;

      rows.push(
        <AdminBalanceRow
          currency={event.Organisation.Currency.Symbol}
          includeReferrals={includeReferrals}
          getName={getName}
          eventTag={eventTag}
          membershipsEnabled={membershipsEnabled}
          hideMoney={hideMoney}
          hideEmail={hideEmail}
          handleRowClick={() => handleRowClick(rowData)}
          key={rowId}
          eventId={event.Id}
          data={rowData}
        />,
      );
    }
  });

  var headerTickets = (
    <th className="grid-header" style={{ textAlign: 'right' }}>
      <a onClick={handleTicketsHeaderClick}>
        {'Tickets'}
        {sortBy == SortBy.TicketCount ? (
          <span>{sortDirection == SortDirections.Ascending ? <img className="sortImage" src={SVGHelper.get('Sort_12')} /> : <img className="sortImage" src={SVGHelper.get('Sort_21')} />}</span>
        ) : null}
      </a>
    </th>
  );

  var headerPrice = (
    <th className="grid-header" style={{ textAlign: 'right' }}>
      <a onClick={handleTotalHeaderClick}>
        Total
        {sortBy == SortBy.TotalSales ? (
          <span>{sortDirection == SortDirections.Ascending ? <img className="sortImage" src={SVGHelper.get('Sort_12')} /> : <img className="sortImage" src={SVGHelper.get('Sort_21')} />}</span>
        ) : null}
      </a>
    </th>
  );

  var balanceOwedHeader = (
    <th className="grid-header" style={{ textAlign: 'right' }}>
      <a onClick={handleBalanceHeaderClick}>
        Balance
        {sortBy == SortBy.Balance ? (
          <span>{sortDirection == SortDirections.Ascending ? <img className="sortImage" src={SVGHelper.get('Sort_12')} /> : <img className="sortImage" src={SVGHelper.get('Sort_21')} />}</span>
        ) : null}
      </a>
    </th>
  );

  if (loading) return <Loader inline>Loading orders...</Loader>;

  return (
    <>
      <Helmet>
        <title>
          #{eventTag} {AdminSection.Balances}
        </title>
        <meta name="description" content={`Manage orders for your events.`} />
      </Helmet>
      <div className="row">
        <div className="col-sm-3 col-md-2 donotprint">
          <SideMenu>
            <h1 className="title">{AdminSection.Balances}</h1>
            {membershipsEnabled && (
              <button className="has-image" onClick={() => setShowOnlyMembers(!showOnlyMembers)}>
                {showOnlyMembers ? <SVGOutlinePersonnel /> : <SVGOutlineMembership />}
                {showOnlyMembers ? 'Show everyone' : 'Show members'}
              </button>
            )}
            {!chartView && (
              <button className="has-image" onClick={() => setHideMoney(!hideMoney)}>
                {hideMoney ? <SVGOutlineMoney /> : <SVGOutlineMoneyHide />}
                {hideMoney ? 'Show money' : 'Hide money'}
              </button>
            )}
            {!chartView && (
              <button className="has-image" onClick={() => setHideEmail(!hideEmail)}>
                {hideEmail ? <SVGOutlineEmail /> : <SVGOutlineEmailHide />}
                {hideEmail ? 'Show emails' : 'Hide emails'}
              </button>
            )}
            {event.ReferralsEnabled && (
              <button className="has-image" onClick={() => setIncludeReferrals(!includeReferrals)}>
                {includeReferrals ? <SVGOutlineReferral /> : <SVGOutlineReferralHide />}
                {!includeReferrals ? 'Show referrals' : 'Hide referrals'}
              </button>
            )}
            <button
              className="has-image"
              onClick={() => {
                setSortBy(SortBy.TicketCount);
                setSortDirection(SortDirections.Decending);
                setSortCookie(SortBy.TicketCount, SortDirections.Decending);

                if (chartView) {
                  setChartView(false);
                } else {
                  setChartView(true);
                }
              }}
            >
              {!chartView ? <SVGOutlineChart /> : <SVGOutlineList />}
              {!chartView ? 'Chart view' : 'List view'}
            </button>
          </SideMenu>
        </div>
        <div className={'col-sm-9 col-md-10 donotprint'}>
          <div className="orderoverview" style={{ paddingBottom: '100px', maxWidth: '900px' }}>
            <div className="input">
              <input id="searchinput" onChange={handleSearchChanged} type="text" placeholder="Search..." value={query} onKeyPress={onFilterKeyPress.bind(this)} style={{ margin: '0' }} />

              <button onClick={clearFilterClicked}>
                <SVGMultiply />
              </button>
            </div>
            <div className="container-outer" style={{ minHeight: '300px' }}>
              <div className="container-inner" style={{ backgroundColor: 'white' }}>
                <table className="table grid-table">
                  <thead>
                    <tr>
                      <th className="grid-header">
                        {chartView ? null : (
                          <>
                            <a onClick={handleEmailHeaderClick}>
                              Email
                              {sortBy == SortBy.Email ? (
                                <span>
                                  {sortDirection == SortDirections.Ascending ? (
                                    <img className="sortImage" src={SVGHelper.get('Sort_AZ')} />
                                  ) : (
                                    <img className="sortImage" src={SVGHelper.get('Sort_ZA')} />
                                  )}
                                </span>
                              ) : null}
                            </a>
                            /
                          </>
                        )}
                        <a onClick={handleNameHeaderClick}>
                          Name
                          {sortBy == SortBy.Name ? (
                            <span>
                              {sortDirection == SortDirections.Ascending ? <img className="sortImage" src={SVGHelper.get('Sort_AZ')} /> : <img className="sortImage" src={SVGHelper.get('Sort_ZA')} />}
                            </span>
                          ) : null}
                        </a>
                      </th>

                      <th className="grid-header"></th>
                      {/* <th className="grid-header" style={{ width: '33px' }}></th> */}
                      {headerTickets}

                      {hideMoney || chartView ? null : headerPrice}
                      {hideMoney || includeReferrals || chartView ? null : balanceOwedHeader}
                    </tr>
                  </thead>

                  {!chartView && <tbody>{rows}</tbody>}
                </table>
                {chartView && (
                  <AdminBalancesChart
                    showMembers={showOnlyMembers}
                    setSelectedEmail={setSelectedEmail}
                    includeReferrals={includeReferrals}
                    event={props.event}
                    rowData={filteredData}
                    getName={getName}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedEmail && (
        <EventAttendeeModal
          organisation={props.organisation}
          event={event}
          email={selectedEmail}
          overviewRefreshed={overviewRefreshed}
          onClose={() => {
            setSelectedEmail(null);
            loadOrdersAndPayments(true);
          }}
        />
      )}
    </>
  );
};

export default AdminBalances;
