import linq from 'linq';
import GeneralApi from '../api/GeneralApi';
import UserHelper from '../helpers/UserHelper';
import { ISeatingPlanSeat } from '../interfaces/ISeatingPlanSeat';
import { ITicket } from '../interfaces/ITicket';
import { ITicketCategory } from '../interfaces/ITicketCategory';
import { IUser } from '../interfaces/IUser';

export interface ITicketAvailability {
  Quantity?: number;
  TicketCategoryGroupId?: number;
  Seats: ITicket[];
  CurrentUser?: IUser;
  EventDate: string;
  DateLocked: string;
  LockedByAdmin?: boolean;
  Id: number;
  EventId: number;
  EventDateId: number;
  AllAvailable: boolean;
  Tickets: Array<ITicket>;
  ErrorMessage: string;
  SessionId: string;
  Email: string;
  DateAsString: string;
  LockInterval: number;
}

export default class TicketService {
  public static getAvailability(eventId: number, eventDateId: number, tickets: Array<ITicket>): Promise<ITicketAvailability> {
    return GeneralApi.request('POST', '/api/TicketAvailability', {
      EventDateId: eventDateId,
      Seats: tickets,
      SessionId: UserHelper.getSessionId(),
    })
      .then((result: ITicketAvailability) => {
        result.Tickets = (result as any).Seats;
        result.EventDateId = eventDateId;
        result.EventId = eventId;

        return result;
      })
      .catch((message) => {
        return {
          AllAvailable: false,
          ErrorMessage: message,
        } as ITicketAvailability;
      });
  }

  public static getSelectedGeneralAdmissionTickets(isAdmin: boolean, tickets: Array<ITicketCategory>): Array<ITicket> {
    return linq
      .from(tickets)
      .where((t: any) => {
        return t.Amount != null && t.Amount > 0;
      })
      .select((tc: ITicketCategory) => {
        const ticketCategory: ITicketCategory = {
          Id: tc.Id,
          Colour: tc.Colour,
          GeneralAdmission: true,
          Guid: tc.Guid,
          Hide: tc.Hide,
          Message: null,
          Name: tc.Name,
          PriceAsInt: tc.PriceAsInt,
        };

        var ticket: ITicket = {
          QuestionAnswers: [],
          GeneralAdmission: true,
          Quantity: tc.Amount,
          TicketCategory: ticketCategory,
          CategoryGroupId: tc.CategoryGroupId,
          TicketCategoryId: ticketCategory.Id,
        };

        return ticket;
      })
      .toArray();
  }

  public static getSelectedSeatedTickets(selectedSeats: Array<ISeatingPlanSeat>): Array<ITicket> {
    return linq
      .from(selectedSeats)
      .where((seat) => {
        return (seat.Resale || !seat.IsOrdered) && !seat.IsRequested && !seat.IsPurchased;
      })
      .select((seat: ISeatingPlanSeat) => {
        var ticketCategory =
          seat.AvailableCategories &&
          linq
            .from(seat.AvailableCategories)
            .orderBy((t) => t.GroupIndex)
            .first();

        var ticket: ITicket = {
          QuestionAnswers: [],
          Quantity: 1,
          GeneralAdmission: false,
          Group: seat.Group,
          Name: seat.Name,
          SeatCategory: seat.SeatCategory,
          SeatCategoryId: seat.SeatCategory.Id,
          TicketCategories: seat.AvailableCategories,
          TicketCategoryId: ticketCategory.Id,
          TicketCategory: ticketCategory,
          Resale: seat.Resale,
        };

        return ticket;
      })
      .toArray();
  }

  public static groupSeatedTickets(seatedTickets: ITicket[]): ITicket[] {
    return linq
      .from(seatedTickets)
      .where((t) => !t.GeneralAdmission)
      .groupBy(
        function (seatedTicket: ITicket) {
          return seatedTicket.TicketCategory.Id;
        },
        (seatedTicket: ITicket) => {
          const ticketCategory = seatedTicket.TicketCategory;
          const seatCategory = seatedTicket.SeatCategory;

          return {
            TicketCategoryId: ticketCategory.Id,
            TicketCategoryGuid: ticketCategory.Guid,
            TicketCategoryName: ticketCategory.Name,
            TicketCategoryPrice: ticketCategory.PriceAsInt,
            SeatCategoryName: seatCategory.Name,
            TicketGroup: seatedTicket.Group,
            TicketName: seatedTicket.Name,
            SeatCategoryId: seatCategory.Id,
            Quantity: 1,
            TicketCategoryColour: ticketCategory.Colour,
            SeatCategoryColour: seatCategory.Colour,
            Description: seatCategory.Description,
            GeneralAdmission: ticketCategory.GeneralAdmission,
          };
        },
        function (ticketCategoryId, grouping) {
          const ticketGroup: ITicket = {
            GeneralAdmission: grouping.first().GeneralAdmission,
            TicketCategory: {
              Id: ticketCategoryId,
              Name: grouping.first().TicketCategoryName,
              PriceAsInt: grouping.sum((item) => {
                return item.TicketCategoryPrice;
              }),
              Guid: grouping.first().TicketCategoryGuid,
              Colour: grouping.first().TicketCategoryColour,
              GeneralAdmission: grouping.first().GeneralAdmission,
            },
            SeatCategory: {
              Id: grouping.first().SeatCategoryId,
              Name: grouping.first().SeatCategoryName,
              Colour: grouping.first().SeatCategoryColour,
              Description: grouping.first().Description,
              Guid: grouping.first().TicketCategoryGuid,
            },
            Quantity: grouping.sum((item) => {
              return item.Quantity;
            }),
            Price: grouping.sum((item) => {
              return item.TicketCategoryPrice;
            }),
          };

          return ticketGroup;
        },
      )
      .orderBy(function (category: any) {
        return category.SeatCategory.Name;
      })
      .thenBy(function (category: any) {
        return category.TicketCategory.Name;
      })
      .toArray();
  }

  public static groupGeneralTickets(generalTickets: ITicket[]): ITicket[] {
    return linq
      .from(generalTickets)
      .where((t) => t.GeneralAdmission)
      .groupBy(
        function (generalTickets: ITicket) {
          return generalTickets.TicketCategory.Id;
        },
        (generalTickets: ITicket) => {
          const ticketCategory = generalTickets.TicketCategory;

          return {
            TicketCategoryId: ticketCategory.Id,
            TicketCategoryGuid: ticketCategory.Guid,
            TicketCategoryName: ticketCategory.Name,
            TicketCategoryPrice: ticketCategory.PriceAsInt,
            TicketGroup: generalTickets.Group,
            TicketName: generalTickets.Name,
            Quantity: generalTickets.Quantity,
            TicketCategoryColour: ticketCategory.Colour,
            GeneralAdmission: ticketCategory.GeneralAdmission,
          };
        },
        function (ticketCategoryId, grouping) {
          const ticketGroup: ITicket = {
            GeneralAdmission: grouping.first().GeneralAdmission,
            TicketCategory: {
              Id: ticketCategoryId,
              Name: grouping.first().TicketCategoryName,
              PriceAsInt: grouping.sum((item) => {
                return item.TicketCategoryPrice;
              }),
              Guid: grouping.first().TicketCategoryGuid,
              Colour: grouping.first().TicketCategoryColour,
              GeneralAdmission: grouping.first().GeneralAdmission,
            },
            Quantity: grouping.sum((item) => {
              return item.Quantity;
            }),
            Price: grouping.sum((item) => {
              return item.TicketCategoryPrice * item.Quantity;
            }),
          };

          return ticketGroup;
        },
      )
      .orderBy(function (category: any) {
        return category.TicketCategory.Name;
      })
      .toArray();
  }
}
