import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import showSignInState from '../atoms/showSignInState';
import showSignUpState from '../atoms/showSignUpState';
import wizardOrganisationState from '../atoms/wizardOrganisationState';
import EventHelper from '../helpers/EventHelper';
import SVGHelper from '../helpers/SVGHelper';
import UserHelper from '../helpers/UserHelper';
import { SignInModal } from '../modals/SignInModal';
import { SignUpModal } from '../modals/SignUpModal';
import SVGSeaty from '../svg/SVGSeaty';
import './Header.scss';
import HeaderUser from './HeaderUser';
import SVGSeatyFace from '../svg/SVGSeatyFace';
import Cookies from 'js-cookie';
import ZIndexes from '../ZIndexes';
import Constants from '../helpers/Constants';
import SVGSearch from '../svg/SVGSearch';

interface IProps {
  initialSearchTerm?: string;
  hideLinks?: boolean;
  transparent?: boolean;
  title?: string;
  alwaysShowSearch?: boolean;
}

const Header: React.FC<IProps> = ({ alwaysShowSearch, initialSearchTerm, transparent, hideLinks, title }) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState(initialSearchTerm ? initialSearchTerm.replace(/%20/g, ' ') : '');
  const currentUser = UserHelper.currentUser;
  const [showSignIn, setShowSignIn] = useRecoilState(showSignInState);
  const [showSignUp, setShowSignUp] = useRecoilState(showSignUpState);
  const [wizardOrganisation, setWizardOrganisation] = useRecoilState(wizardOrganisationState);

  return (
    <>
      <header
        style={{ zIndex: ZIndexes.APP_HEADER }}
        className={'app-header donotprint' + (alwaysShowSearch ? ' always-show-search' : '') + (open ? ' open' : '') + (transparent ? ' transparent' : '')}
      >
        <div className="header-content">
          <Link className="logo menu-button" to="/">
            {title ? (
              <div className="seaty-face">
                <SVGSeatyFace />
              </div>
            ) : (
              <SVGSeaty />
            )}
          </Link>

          {title && (
            <div className="title">
              <h1>{title}</h1>
            </div>
          )}

          <div className="hide-when-transparent header-search">
            <SVGSearch />
            <form className="search-form" action={`/Search/${searchText}`}>
              <input value={searchText} onChange={(e) => setSearchText(e.target.value)} autoComplete="off" type="search" placeholder="Search events..." className="form-control" />
              {searchText && searchText.length > 0 && <button type="submit">Search</button>}
            </form>
          </div>

          <span className="spacer" />
          {currentUser ? (
            <>
              <Link className="menu-button hide-when-transparent" to="/My/Tickets">
                My Tickets
              </Link>
              <button onClick={() => EventHelper.manageEvents(navigate)} className="menu-button hide-when-transparent">
                My Events
              </button>
              <button
                className="menu-button hide-when-transparent"
                onClick={() => {
                  setWizardOrganisation({ onClose: () => setWizardOrganisation(null) });
                }}
              >
                Create Event
              </button>
            </>
          ) : (
            !hideLinks && (
              <>
                <Link className="menu-button hide-when-transparent" to="/Home/MoreInfo">
                  Learn More
                </Link>
                <Link className="menu-button hide-when-transparent" to="/Home/Fees">
                  Fees
                </Link>
              </>
            )
          )}

          {currentUser ? (
            <>
              <HeaderUser className="hide-on-small" />

              <button
                className="menu-button show-on-small"
                onClick={() => {
                  window.localStorage.removeItem(Constants.SeatyUserVar);
                  window.location.href = '/';
                  Cookies.remove('last-managed-organisation');
                }}
              >
                Sign Out
              </button>
            </>
          ) : (
            <>
              <button className="menu-button" onClick={() => setShowSignIn(true)}>
                Sign In
              </button>
              <button className="menu-button" onClick={() => setShowSignUp(true)}>
                Sign Up
              </button>
            </>
          )}

          {/* <Link to={'/'} className="powered-by-header">
            <img alt="Powered by Seaty" src="https://seatylive.blob.core.windows.net/images/FooterBackground2.png"></img>
          </Link> */}

          <span className="burger" onClick={() => setOpen(!open)}>
            <div className={`hamburger hamburger--squeeze js-hamburger${open ? ' is-active' : ''}`}>
              <div className="hamburger-box">
                <div className="hamburger-inner"></div>
              </div>
            </div>
          </span>
        </div>
      </header>

      {showSignIn && <SignInModal onClose={() => setShowSignIn(false)} />}
      {showSignUp && <SignUpModal onClose={() => setShowSignUp(false)} />}
    </>
  );
};

export default Header;
