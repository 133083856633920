import moment from 'moment';
import React, { useEffect, useState } from 'react';
import AdminApi from '../../../api/AdminApi';
import DateAndTimePicker from '../../../components/DateAndTimePicker';
import Dropdown from '../../../components/Dropdown';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import Switch from '../../../components/Switch';
import { IEvent } from '../../../interfaces/IEvent';
import Toolbar from '../toolbar/Toolbar';
import { IDropDownItem } from '../../../components/DropdownItem';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EventHelper from '../../../helpers/EventHelper';
import Constants from '../../../helpers/Constants';

export interface IProps {
  event: IEvent;
  onEventUpdated: (e: IEvent) => void;
  globalOptions: JSX.Element;
}

const EventSection: React.FC<IProps> = (props) => {
  const { event, onEventUpdated } = props;
  const description = event.Description ? event.Description : '';

  const charsUsed = EventHelper.getRichTextCharLength(description);
  const charsRemaining = Constants.MaxEventDescriptionLength - charsUsed;

  const [timeZones, setTimeZones] = useState(null);

  useEffect(() => {
    AdminApi.request('GET', '/api/TimeZone').then((result) => {
      setTimeZones(result);
    });
  }, []);

  return (
    <>
      <Toolbar>
        <div className="options">{props.globalOptions}</div>
      </Toolbar>
      <div className="row">
        <div className="col-sm-8">
          <div className="fields">
            <h2>Details</h2>
            <div className="section event-information">
              <InfoLabel required text="What is the name of your event?" tooltip="All events need a name!" />
              <InfoInput labelText="Event name" onChange={(value) => onEventUpdated({ ...event, Name: value })} value={event.Name} />

              <InfoLabel required text="What unique event tag would you like?" tooltip="An event tag is a short-cut link to your event. Anyone can jump to your event using Seaty.co.uk/YourTag. " />
              <InfoInput maxLength={22} labelText="Seaty.co.uk/" onChange={(value) => onEventUpdated({ ...event, EventTag: value })} value={event.EventTag} />

              <InfoLabel
                text="When will your event be live?"
                tooltip="Enter or select a date and time that you want your event to appear online. From this date and time people will be able to request or buy tickets according to your event setup."
              />

              <DateAndTimePicker
                groupName="GoLive"
                initialValue={moment(event.OnlineFrom)}
                onChange={(dateTime) => {
                  if (dateTime.isDateTime && dateTime.moment) {
                    event.OnlineFrom = dateTime.moment.format('YYYY-MM-DD HH:mm:ss.SSS');
                    onEventUpdated({ ...event });
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="info">
            Easily update your event's details here and find more options (dates, venue, images, etc.) in the editor menu.
            <br />
            <br />
            Remember to <strong>save frequently</strong> to avoid data loss – it's best to save after each section! Happy editing! 🎉
          </div>
        </div>

        <div className="col-sm-8">
          <div className="fields">
            <h2>Privacy</h2>
            <div className="section">
              <label className="label">Is this event private?</label>

              <Switch trueLabel="Yes, this event is private" falseLabel="No, this event is public" onChange={(value) => onEventUpdated({ ...event, Private: value })} checked={event.Private}></Switch>

              <div
                style={{
                  display: event.Private == true ? 'block' : 'none',
                }}
              >
                <InfoInput labelText="Event password" maxLength={100} onChange={(value) => onEventUpdated({ ...event, PrivatePassword: value })} value={event.PrivatePassword} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="info">
            Making an event <strong>private</strong> 🔒 hides it from the public. To book tickets, a password is needed. Without a password, only admins can access it. Admins don't need a password,
            and you can change this setting later.
          </div>
        </div>

        <div className="col-sm-8">
          <div className="fields">
            <h2>About your event</h2>

            <div className="section event-information">
              <div className="section">
                <label className="label">
                  How would you describe your event? ({charsUsed}/{Constants.MaxEventDescriptionLength})
                </label>

                <ReactQuill
                  theme="snow"
                  value={event.Description}
                  onChange={(v) => {
                    onEventUpdated({ ...event, Description: v });
                  }}
                  readOnly={charsUsed == charsRemaining}
                  modules={{
                    toolbar: [
                      [{ header: '1' }, { header: '2' }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                    ],
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          {charsUsed > Constants.MaxEventDescriptionLength && (
            <div className="info bad">Oops! Your event description is too long. Try and keep the character count to less than {Constants.MaxEventDescriptionLength}.</div>
          )}

          <div className="info">
            An event <strong>description</strong> 📝 is really important! Make sure to include all necessary info to accurately describe your event for your customers. 🎯
          </div>
        </div>

        <div className="col-sm-8">
          <div className="fields">
            <h2>Terms & conditions</h2>
            <div className="section">
              <InfoInput rows={14} labelText="Terms & conditions" onChange={(value) => onEventUpdated({ ...event, TermsAndConditions: value })} value={event.TermsAndConditions} />
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="info">
            When customers place an order, they'll see your <strong>terms & conditions</strong>. Just a heads-up, Seaty isn't responsible for enforcing them – that's up to you. 😊
          </div>
        </div>

        <div className="col-sm-8">
          <div className="fields">
            <h2>Timezone</h2>
            <div className="section">
              <InfoLabel text="What time zone is your event in?" tooltip="This is important as it is the timezone that will be used when your event goes live." />
              {timeZones ? (
                <Dropdown
                  onChange={(e: IDropDownItem) => onEventUpdated({ ...event, TimeZoneId: e.value })}
                  value={event.TimeZoneId}
                  items={timeZones.map((tz) => {
                    return { value: tz.value, description: tz.description };
                  })}
                  description="Time zone"
                />
              ) : (
                <p>Loading timezones...</p>
              )}{' '}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventSection;
