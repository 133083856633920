import 'moment-timezone';
import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRecoilState } from 'recoil';
import loaderState from '../../atoms/loaderState';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Theme from '../../components/Theme';
import EnvironmentVariables from '../../EnvironmentVariables';
import CacheHelper from '../../helpers/CacheHelper';
import LocationHelper from '../../helpers/LocationHelper';
import ThemeHelper from '../../helpers/ThemeHelper';
import UserHelper from '../../helpers/UserHelper';
import { IOrganisation } from '../../interfaces/IOrganisation';
import AdminPage from '../Admin/AdminPage';
import OrganisationDetails from './OrganisationDetails';
import './OrganisationPage.scss';
import PixelHelper from '../../helpers/PixelHelper';
import NeedHelp from '../../components/NeedHelp';

export interface IProps {}

const OrganisationLandingPage: FunctionComponent<IProps> = (props) => {
  const [organisation, setOrganisation] = useState<IOrganisation>();
  const eventUrlDetails = LocationHelper.getLocation().pathname;
  const organisationTag = eventUrlDetails.split('/')[2];
  const [loaders, setLoaders] = useRecoilState(loaderState);

  useEffect(() => {
    const fetchData = async () => {
      const organisationCache: IOrganisation = await CacheHelper.organisationByTagWithMessage(setLoaders, loaders, organisationTag);
      setOrganisation(organisationCache);

      let pixel = false;
      if (organisationCache.FacebookPixelId) {
        pixel = true;
        PixelHelper.init(organisationCache.FacebookPixelId, null, {
          autoConfig: true,
          debug: EnvironmentVariables.isDev(),
        });
      }
      if (pixel) PixelHelper.pageView();
    };

    fetchData();
  }, []);

  const currentUser = UserHelper.currentUser;
  const isAdmin = organisation && currentUser && (currentUser.Id === 1 || (currentUser.Organisations && currentUser.Organisations.includes(organisation.Id)));

  const organisationPage = organisation && (
    <div>
      {/* {isAdmin && (
        <>
          <div className="info-message">
            Here is how your organisation's landing page appears to customers visiting Seaty.co.uk/Organisation/{organisation.OrganisationTag}. <br />
            As an admin, you'll see extra options above, but rest assured, they're for your eyes only! 👀
          </div>
        </>
      )} */}

      <OrganisationDetails hideBorder={true} organisation={organisation} />

      <NeedHelp organisation={organisation} />
    </div>
  );

  const theme = organisation && organisation.Theme;
  const useTheme = theme && theme.BackgroundImageUrl !== ThemeHelper.getDefaultTheme().BackgroundImageUrl;

  return (
    <>
      <Helmet>
        <meta property="og:url" content={`https://Seaty.co.uk/Organisation/${organisationTag}`} />
        <meta property="fb:app_id" content="747095462055934" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`#${organisationTag}`} />
        <meta property="og:description" content={`#${organisationTag}.`} />
        <meta property="og:image" content={organisation ? organisation.LogoUrl : ''} />
        <title>#{organisationTag}</title>
        <meta name="description" content={`#${organisationTag}.`} />
      </Helmet>
      {isAdmin ? (
        <AdminPage>
          <div className="organisation-page">
            {useTheme ? (
              <Theme hideFooter backgroundClassName={`seaty-modal-background`} parentClassName="organisation-theme" className="" theme={theme}>
                <div className={`ticket-rip page`} />
                {organisationPage}
                <div className="ticket-rip page bottom" />
              </Theme>
            ) : (
              <>{organisationPage}</>
            )}
          </div>
        </AdminPage>
      ) : (
        <>
          <Header transparent={useTheme} />

          <div className="organisation-page">
            {useTheme ? (
              <Theme hideFooter backgroundClassName={`seaty-modal-background`} parentClassName="organisation-theme" className="" theme={theme}>
                <div className={`ticket-rip page${isAdmin ? ' dark' : ''}`} />
                {organisationPage}
                <div className="ticket-rip page bottom" />
              </Theme>
            ) : (
              <>{organisationPage}</>
            )}
          </div>

          <Footer transparent={useTheme} />
        </>
      )}
    </>
  );
};

export default OrganisationLandingPage;
