import { FunctionComponent, useState } from 'react';
import NumberHelper from '../../../../helpers/NumberHelper';
import SVGMinus from '../../../../svg/SVGMinus';
import SVGPlus from '../../../../svg/SVGPlus';

export interface IProps {
  title: string;
  subtitle?: string;
  titleColour?: string;
  subtitleColour?: string;
  amount: string;
  people?: string;
  percentage?: number;
  total: string;
  child?: any;
  wording?: string;
  bold?: boolean;
}

const EventSummary_TicketSalesReportRow: FunctionComponent<IProps> = (props) => {
  return (
    <>
      <tr style={{ fontWeight: props.bold ? 700 : null }}>
        <td>
          <span style={{ color: props.titleColour ? props.titleColour : '' }}>{props.title}</span>
          <span style={{ color: props.subtitleColour ? props.subtitleColour : '' }}> {props.subtitle}</span>
        </td>
        <td className="right">{NumberHelper.isNumeric(props.percentage) ? <span> {props.percentage == 100 ? 100 : props.percentage.toFixed(1)}%</span> : null}</td>
        <td className="right">
          <div>
            {props.people ? (
              <div>
                {props.amount}
                <div>
                  {props.people} <span style={{ color: '#bbbbbb' }}>People</span>
                </div>
              </div>
            ) : (
              <div>
                {props.amount}
                {props.wording && <span style={{ color: '#bbbbbb' }}> {props.wording}</span>}
              </div>
            )}
          </div>
        </td>
        <td className="right">
          <div>{props.total}</div>
        </td>
      </tr>
      {props.child ? props.child : null}
    </>
  );
};

export default EventSummary_TicketSalesReportRow;
