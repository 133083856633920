import React from 'react';
import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import EnvironmentVariables from '../../EnvironmentVariables';
import SVGHelper from '../../helpers/SVGHelper';
import './MoreInfo.scss';

interface IProps {}

const MoreInfoPage: React.FC<IProps> = (props) => {
  return (
    <>
      <Helmet>
        <title>Seaty - About Us</title>
        <meta name="description" content={`Information on Seaty.co.uk.`} />
      </Helmet>
      <Header />

      <div className="banner" style={{ background: 'none', marginTop: '20px', marginBottom: '40px' }}>
        <span>Check out some of the things </span>Seaty<span> can do</span>
      </div>

      <div className="bubbles" style={{ paddingBottom: '30px' }}>
        <div className="row info-blocks">
          <div className="col col-sm-4">
            <div className="content-box ">
              <div className="title">
                <div className="svg-icon">
                  <img src={SVGHelper.get('TicketHome')} />
                </div>
                <span className="text">Custom Event Home</span>
              </div>

              <p>Create a home for your event's box office with Seaty. A place to share and manage all your ticket sales.</p>
            </div>

            <div className="content-box">
              <div className="title">
                <div className="svg-icon">
                  <img src={SVGHelper.get('Seating')} />
                </div>
                <span className="text">Seating Plans</span>
              </div>

              <p>Seaty provides a simple online seating plan editor. Seating plans are a clean and elegant way to sell your tickets. Ours are fully mobile friendly and easy to use.</p>
            </div>

            <div className="content-box">
              <div className="title">
                <div className="svg-icon">
                  <img src={SVGHelper.get('Online Payment')} />
                </div>
                <span className="text">Online Card Payments</span>
              </div>

              <p>
                Seaty payment processing can handle card payments for your tickets through Stripe, our secure provider.
                <br />
                <br />
                Our payment processing service is available for an <strong>{EnvironmentVariables.FEE_PERCENTAGE}%</strong> flat fee that you can either absorb in the cost of a ticket, or pass on to
                the attendees that want to pay by card. All fees are made clear at the time of purchase.
                <br />
                <br />
                We charge no fee for creating events and booking or managing tickets through Seaty from your own box office.
              </p>
            </div>

            <div className="content-box">
              <div className="title">
                <div className="svg-icon">
                  <img src={SVGHelper.get('Password Check')} />
                </div>
                <span className="text">Safe and Secure</span>
              </div>

              <p>All order requests have to be approved by the event admins. All online payment orders are done over our provider's secure connection.</p>
            </div>
          </div>

          <div className="col col-sm-4">
            <div className="content-box">
              <div className="title">
                <div className="svg-icon">
                  <img src={SVGHelper.get('Megaphone')} />
                </div>
                <span className="text">Seat Requests</span>
              </div>

              <p>
                If you decide so, users of Seaty can request available seats online through your events seating plans. Request notifications will be emailed to you and can then be approved or refused
                as you deem fit, payments for requested seats are collected as you please in agreement with the attendee.
              </p>
            </div>

            <div className="content-box">
              <div className="title">
                <div className="svg-icon">
                  <img src={SVGHelper.get('Conference')} />
                </div>
                <span className="text">Features for Everyone!</span>
              </div>

              <p>
                Seaty focuses its features on more than just the event admins. Attendees that book tickets through Seaty can log in and manage their ticket sales.
                <br />
                <br />
                Seaty supports event attendees by allowing them to clearly see what they've booked, where they're sitting, what they owe and when they've made payments.
              </p>
            </div>
            <div className="content-box">
              <div className="title">
                <div className="svg-icon">
                  <img src={SVGHelper.get('Hand With Pen')} />
                </div>
                <span className="text">Ticket Notes</span>
              </div>

              <p>
                We've made it possible for attendees to add notes to tickets to help manage their purchases easily. Their notes will even appear on the ticket.
                <br />
                <br />
                As an administrator, you can even add your own notes to orders & tickets that only you and other admins can see.
              </p>
            </div>

            <div className="content-box">
              <div className="title">
                <div className="svg-icon">
                  <img src={SVGHelper.get('Positive Dynamic')} />
                </div>

                <span className="text">Sales Reports</span>
              </div>

              <p>
                Access real-time ticket sales reports and the amounts paid and due for collection. Event planners and attendees can track seat orders, what is owed and what has been paid for an event
                through Seaty.
              </p>
            </div>

            <div className="content-box">
              <div className="title">
                <div className="svg-icon">
                  <img src={SVGHelper.get('Trust')} />
                </div>

                <span className="text">Reliability</span>
              </div>

              <p>Seaty is hosted on Microsoft Azure servers in the United Kingdom.</p>
            </div>
          </div>

          <div className="col col-sm-4">
            <div className="content-box">
              <div className="title">
                <div className="svg-icon">
                  <img src={SVGHelper.get('Form')} />
                </div>
                <span className="text">Simple Forms</span>
              </div>

              <p>We won't overload your attendees with dozens of forms and fields to fill out. With Seaty people can buy tickets in a few easy steps!</p>
            </div>

            <div className="content-box">
              <div className="title">
                <div className="svg-icon">
                  <img src={SVGHelper.get('Remove User Male')} />
                </div>
                <span className="text">No User Account Required</span>
              </div>

              <p>
                At Seaty we want to sell your tickets as efficiently as possible. We will not force your attendees to make an account in order to buy tickets. Your tickets are available 24/7 to
                anybody that wants them.
              </p>
            </div>

            <div className="content-box">
              <div className="title">
                <div className="svg-icon">
                  <img src={SVGHelper.get('Contacts')} />
                </div>
                <span className="text">Share & Care</span>
              </div>

              <p>Get the word out to potential attendees through social media and your very own unique event link Seaty.co.uk/EventLink.</p>
            </div>

            <div className="content-box">
              <div className="title">
                <div className="svg-icon">
                  <img src={SVGHelper.get('Paper Plane')} />
                </div>
                <span className="text">No More Paper</span>
              </div>

              <p>
                Get rid of paper notes and complicated spreadsheets that get lost or misplaced. Seaty provides a simple and secure platform shared by all members of the ticketing team, with order and
                payment dashboards available for admins, agents and attendees.
              </p>
            </div>
            {/*
            <div className="content-box">
              <div className="title">
                <div className="svg-icon">
                  <img src={SVGHelper.get("Alarm Clock")} />
                </div>
                <span className="text">Real Time Seat Availability</span>
              </div>

              <p>Complete transparency of available seats that are updated in real time. Seaty makes knowing what seats remain available fast, clear and easy for everyone!</p>
            </div> */}
          </div>
        </div>
      </div>

      <div className="banner" style={{ background: 'none', marginTop: '20px', marginBottom: '40px' }}>
        <span>Check out this full list of features for more details</span>
      </div>

      <div className="bubbles" style={{ paddingBottom: '50px' }}>
        <div className="row">
          <div className="col col-sm-8 col-sm-offset-2">
            <div className="content-box" style={{ padding: 0 }}>
              <div className="smooth-details">
                <div className="heading">Seaty</div>
                <div className="field">
                  <label>Online ticket sales</label>
                  <span>You can sell your tickets through your own online event page. </span>
                </div>
                <div className="field">
                  <label>Box office ticket sales</label>
                  <span>As an event organiser, you can sell tickets through your own box office any way you like and still manage the order through Seaty. This service is completely free!</span>
                </div>
                <div className="field">
                  <label>Box office telephone hosting</label>
                  <span>We will show your box office telephone on your event's landing page.</span>
                </div>
                <div className="field">
                  <label>UK Based servers</label>
                  <span>All our servers are hosted by Microsoft and based in the south of the UK near London.</span>
                </div>
                <div className="field">
                  <label>Referrals</label>
                  <span>You can allow people to leave a referral to other members when they order tickets.</span>
                </div>
                <div className="field">
                  <label>Live chat</label>
                  <span>You can speak to us at any time using the live chat button on the bottom right of every page. One of our team will be back in touch with you quickly.</span>
                </div>
                <div className="field">
                  <label>Chat for organisations</label>
                  <span>There is a built in system to allow attendees to message organisations running events through the same live chat system you use to speak with Seaty. </span>
                </div>
                <div className="field">
                  <label>Simplicity</label>
                  <span>Seaty is designed with ease of use in mind. Our website is fully mobile compatible and our user interface is clean and simple.</span>
                </div>
                <div className="field">
                  <label>We listen to you</label>
                  <span>
                    Seaty is built alongside the people that use it. The most important thing for us is listening to your ideas and where we think an idea can work for everyone, making it happen.
                  </span>
                </div>
              </div>
            </div>

            <div className="content-box" style={{ padding: 0 }}>
              <div className="smooth-details">
                <div className="heading">Tickets</div>
                <div className="field">
                  <label>Print at home or show on phone e-tickets</label>
                  <span>Our e-tickets can be printed at home and are completely mobile compatible if you wish to allow attendees to show them on their phones.</span>
                </div>
                <div className="field">
                  <label>Logo branding</label>
                  <span>You can personalise your e-tickets and add your own company logo.</span>
                </div>
                <div className="field">
                  <label>2D QR barcodes</label>
                  <span>You can optionally enable barcodes on your tickets which allow you to use the Seaty app to scan them to check validity.</span>
                </div>
                <div className="field">
                  <label>Printable tickets</label>
                  <span>Our tickets are plain and simple and multiple tickets can fit on a single page. You can view tickets yourself as an organiser or have people print their tickets at home.</span>
                </div>
              </div>
            </div>

            <div className="content-box" style={{ padding: 0 }}>
              <div className="smooth-details">
                <div className="heading">Ordering</div>
                <div className="field">
                  <label>No long forms</label>
                  <span>
                    {' '}
                    We will not overwhelm your guests by making them fill in dozens of fields of personal information before they can order a ticket. We take only the information we need and make
                    buying your tickets a breeze.
                  </span>
                </div>
                <div className="field">
                  <label>Single page orders</label>
                  <span>
                    Our order process happens directly from your event page. We do not require attendees to jump to and load lots of different pages when ordering their tickets, we make it quick and
                    simple.
                  </span>
                </div>
                <div className="field">
                  <label>Ticket requests</label>
                  <span>
                    If you enable ticket requests you can allow your attendees to ask for tickets. As an organiser you will then be emailed and you can log in to accept/refuse a the request. The
                    payment for the tickets can then be logged when the attendee/your member has paid. This process can be password protected.
                  </span>
                </div>
                <div className="field">
                  <label>Real-time updates</label>
                  <span>When someone books or locks a ticket that ticket will instantly become unavailable to anyone that is looking at your seating plan.</span>
                </div>
                <div className="field">
                  <label>Order notes</label>
                  <span>Notes can be added to all orders and to each individual ticket.</span>
                </div>
              </div>
            </div>

            <div className="content-box" style={{ padding: 0 }}>
              <div className="smooth-details">
                <div className="heading">Events</div>
                <div className="field">
                  <label>Your own website address</label>
                  <span>You will get to create your own unique event tag for your event that will allow anyone to access it any buy tickets by jumping to Seaty.co.uk/YourEventTag</span>
                </div>
                <div className="field">
                  <label>Your own terms and conditions</label>
                  <span>You can add terms and conditions to your event that will show and be printed on every single ticket.</span>
                </div>
                <div className="field">
                  <label>Your own event landing page</label>
                  <span>You get your own event landing page where you can add all the additional information you like, as well as show off any awards your company has won.</span>
                </div>
                <div className="field">
                  <label>Interactive event editor</label>
                  <span>You are in control of your event. We will provide you with a feature rich event editor that is simple to use and gives you information at each step.</span>
                </div>
                <div className="field">
                  <label>Interactive seating plan editor</label>
                  <span>
                    Our seating plan editor makes it fast and easy to make and label seats. We also give you great tools for adding walkways, toilets, a stage and other options to seating plans.
                  </span>
                </div>
                <div className="field">
                  <label>General admission tickets</label>
                  <span>As well as seated tickets we give you the option to make general admission tickets for your event with fixed and shared quantities.</span>
                </div>
                <div className="field">
                  <label>Venue information</label>
                  <span>We give you a dedicated section for adding venue information to your event's landing page.</span>
                </div>
                <div className="field">
                  <label>Organisation information</label>
                  <span>We give you a dedicated section for adding information about your organisation and a link to your own web page from your event's landing page.</span>
                </div>
                <div className="field">
                  <label>Event activation and deactivation</label>
                  <span>You can choose when your event is visible and when any of your event dates are available to the public.</span>
                </div>
                <div className="field">
                  <label>Unlimited seat and price categories</label>
                  <span>
                    You can set up any number or combination of seat and price categories for your tickets. All seat categories get their own colour to make them really visible in your seating plan.
                  </span>
                </div>
                <div className="field">
                  <label>Organiser only price categories</label>
                  <span>You can set up certain tickets so they can only be seen by an event organiser.</span>
                </div>
                <div className="field">
                  <label>Complimentary tickets</label>
                  <span>We allow you to give tickets away for free as an event organiser to whoever you wish.</span>
                </div>
                <div className="field">
                  <label>Printable seating plans</label>
                  <span>Our seating plans are colourful and vibrant and waiting for you to print them. </span>
                </div>
                <div className="field">
                  <label>Feature on our home page</label>
                  <span>If you let us know we would be happy to feature your event on our home page to help you boost sales. </span>
                </div>
              </div>
            </div>

            <div className="content-box" style={{ padding: 0 }}>
              <div className="smooth-details">
                <div className="heading">Mobile App for iPhone, iPad and iPod Touch</div>
                <div className="field">
                  <label>Barcode scanning</label>
                  <span>You can download our free app to scan ticket barcodes. You don't need to rent or have any expensive equipment in order to scan your barcodes. </span>
                </div>
                <div className="field">
                  <label>Manage ticket scans</label>
                  <span>
                    You can go to your events scan overview page and see ticket scans in the list. Orders that appear can be managed as normal as an administrator making this a great way to manage
                    tickets in a physical box office.{' '}
                  </span>
                </div>
                <div className="field">
                  <label>Get people in quickly</label>
                  <span>
                    The Seaty app scans for tickets continuously. There is no delay between ticket scans and you can keep scanning as people come through the door. Meaning you can get people seated as
                    quickly as possible.{' '}
                  </span>
                </div>
                <a href="https://itunes.apple.com/us/app/seaty-ticket-scan/id941385198?ls=1&mt=8">Download the Seaty iOS app</a>
              </div>
            </div>

            <div className="content-box" style={{ padding: 0 }}>
              <div className="smooth-details">
                <div className="heading">Event Organisers / Admins</div>
                <div className="field">
                  <label>Unique event page</label>
                  <span>When you log into an event page as an administrator you will have unique admin options and features that work alongside the same view everyone else has of your events.</span>
                </div>
                <div className="field">
                  <label>Notes on tickets</label>
                  <span>As an administrator you can write notes against each order and each individual ticket that can only be seen by administrators. </span>
                </div>
                <div className="field">
                  <label>Front of house tools</label>
                  <span>You can use the event landing page to easily manage and search for tickets at front of house. </span>
                </div>
                <div className="field">
                  <label>Refunds & cancellations</label>
                  <span>As an event organiser you have the power to issue cancellations with or without refunds at any time.</span>
                </div>
                <div className="field">
                  <label>Independant payments</label>
                  <span>
                    We allow you to log payments that you have accepted outside of Seaty at any time. You don't even have to assign a payment or refund to a specific ticket if you don't want to you
                    can just make a payment against a users balance at any amount you like and it's all clearly tracked in the system.
                  </span>
                </div>
                <div className="field">
                  <label>Point and click orders in seating plan</label>
                  <span>As an event organiser you can see an order at any time from the seating plan by clicking on a seat. It will even show you other tickets in the order.</span>
                </div>
                <div className="field">
                  <label>Transfer orders</label>
                  <span>You can transfer orders from an existing user to another one without having to cancel them, the old and new owner of the tickets will be emailed.</span>
                </div>
                <div className="field">
                  <label>Re-print or re-send tickets</label>
                  <span>You can print or re-send tickets at any time as an event organiser.</span>
                </div>
                <div className="field">
                  <label>Balance statments</label>
                  <span>Administrators can easily generate balance invoices for any money outstanding for tickets issued. The statement shows all orders and all payments made.</span>
                </div>
              </div>
            </div>

            <div className="content-box" style={{ padding: 0 }}>
              <div className="smooth-details">
                <div className="heading">Attendees</div>
                <div className="field">
                  <label>Tickets overview</label>
                  <span>
                    Attendees can view all of their tickets for all of their events at any time by creating an account on Seaty. They can also see if they have an outstanding balance for any event.{' '}
                  </span>
                </div>
                <div className="field">
                  <label>Balance payments</label>
                  <span>If an attendee owes money for tickets they have been issued they can pay off their balance at any time from the event's page. </span>
                </div>
                <div className="field">
                  <label>Notes on tickets</label>
                  <span>
                    All attendees can write notes on each of their tickets to easily keep track of which ticket is for who. All attendee notes will be printed on each ticket for easy reference.{' '}
                  </span>
                </div>
              </div>
            </div>

            <div className="content-box" style={{ padding: 0 }}>
              <div className="smooth-details">
                <div className="heading">Discounts</div>
                <div className="field">
                  <label>Codes or Automatic</label>
                  <span>You can create a discount that is applied automatically as criteria is met or that require a specific discount code to be entered at checkout. </span>
                </div>
                <div className="field">
                  <label>Specific ticket criteria</label>
                  <span>Discounts can have a specific set of criteria. You can make a discount that requires a specific amount of a certain ticket to be inside an order to become active. </span>
                </div>
                <div className="field">
                  <label>Group discounts</label>
                  <span>You can create discounts that require a certain number of tickets to be ordered in total to become active. </span>
                </div>
                <div className="field">
                  <label>Many types of discount</label>
                  <span>
                    When all of the criteria has been met your discount can offer a percentage off the total order, a fixed amount off the total order or any number of the cheapest tickets in the
                    order free as they end result.{' '}
                  </span>
                </div>
              </div>
            </div>

            <div className="content-box" style={{ padding: 0 }}>
              <div className="smooth-details">
                <div className="heading">Reporting</div>
                <div className="field">
                  <label>Event summary dashboard</label>
                  <span>
                    The event summary page gives you a single location to view all the total sales for your tickets with breakdowns on each ticket category. There are also a series of printable charts
                    and graphs that give clear analytics of weekly and total sales.{' '}
                  </span>
                </div>
                <div className="field">
                  <label>Orders and payments dashboard</label>
                  <span>
                    We have created an interactive orders and payments page that allows you to view all the orders and payments by attendee and trace who has sold the most tickets. This page also lets
                    you manage every aspect of an attendees order.{' '}
                  </span>
                </div>
                <div className="field">
                  <label>Event comparisons</label>
                  <span>
                    We have created charts for comparing events sales week by week before an event began. So you can easily tell how well one event is doing compared to a previous similar one from the
                    past.{' '}
                  </span>
                </div>
                <div className="field">
                  <label>Projections</label>
                  <span>We allow you to see your average sale price to help with budgeting and projections. </span>
                </div>
                <div className="field">
                  <label>Data export</label>
                  <span>
                    You can export the raw data for your event from Seaty at any time in Excel format. This will allow you to make any reports you want. All order, cancellation and payment information
                    is included inside the spreadsheet in its rawest form for you to play with.{' '}
                  </span>
                </div>
                <div className="field">
                  <label>Comprehensive order searching</label>
                  <span>
                    We have made a search system for orders that let's you find them easily filtered by order ID, attendee name, admin notes and other order information. You can even access it as an
                    admin from the event landing page.{' '}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MoreInfoPage;
