import linq from 'linq';
import React, { useState, useEffect } from 'react';
import ColourHelper from '../../../helpers/ColourHelper';
import GuidHelper from '../../../helpers/GuidHelper';
import { ICategoryGroup, IEvent } from '../../../interfaces/IEvent';
import { ITicketCategory } from '../../../interfaces/ITicketCategory';
import SVGTicket from '../../../svg/SVGTicket';
import Toolbar from '../toolbar/Toolbar';
import SeatedTicket from './SeatedTicket';
import Loader from '../../../components/Loader';

export interface IProps {
  event: IEvent;
  onEventUpdated: (e: IEvent) => void;
  globalOptions: JSX.Element;
}

const SeatedTicketsSection: React.FC<IProps> = (props) => {
  const { event, onEventUpdated } = props;
  const [dragOver, setDragOver] = useState<ICategoryGroup>(null);
  const [dragging, setDragging] = useState<ICategoryGroup>(null);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [showColourPicker, setShowColourPicker] = useState(false);
  const [loading, setLoading] = useState(true);

  const getItems = () => {
    return linq
      .from(event.AllocatedCategoryGroups)
      .orderBy((s) => s.Index)
      .toArray();
  };

  const resetIndexes = () => {
    getItems().forEach((i, index) => (i.Index = index));
    props.onEventUpdated({ ...event });
  };

  useEffect(() => {
    if (event.AllocatedCategoryGroups.filter((acg) => acg.Categories == null || acg.Categories.length == 0).length > 0) {
      event.AllocatedCategoryGroups = event.AllocatedCategoryGroups.filter((acg) => acg.Categories != null && acg.Categories.length > 0);
    }
    resetIndexes();
    setLoading(false);
  }, []);

  const handleDragStart = (index, group: ICategoryGroup) => (e) => {
    setDragging(group);
    setDragOver(null);
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', null);
  };

  const handleDragOver = (index, group: ICategoryGroup) => (e) => {
    e.preventDefault();
    if (draggedIndex !== null && draggedIndex !== index) {
      setDragOver(group);
    }
  };

  const handleDragLeave = (e) => {
    e.target.classList.remove('drag-over');
    setDragOver(null);
  };

  const handleDrop = (e) => {
    if (!dragging || !dragOver) return;

    e.preventDefault();
    e.target.classList.remove('drag-over');

    if (dragOver.Index != dragging.Index) {
      // dragOver.ChangesMade = true;
      // dragging.ChangesMade = true;

      if (dragOver.Index > dragging.Index) {
        dragging.Index = dragOver.Index + 0.5;
      } else if (dragOver.Index < dragging.Index) {
        dragging.Index = dragOver.Index - 0.5;
      }

      const newList = getItems();
      newList.forEach((sp, index) => {
        sp.Index = index;
      });
      event.AllocatedCategoryGroups = newList;
      props.onEventUpdated({ ...event });
    }

    setDragOver(null);
    setDraggedIndex(null);
    setDragging(null);
  };

  const addNew = () => {
    var color = ColourHelper.getRandomColor();

    event.Dates.forEach((ed) => {
      if (ed) ed.ChangeMade = true;
    });

    const categories: Array<ITicketCategory> = [];

    event.SeatingPlans.forEach((seatingPlan) => {
      linq
        .from(seatingPlan.SeatCategories)
        .orderBy((sc) => sc.Name)
        .toArray()
        .forEach((seatCategory) => {
          categories.push({
            Id: 0,
            People: 1,
            Description: '',
            Name: '',
            HasOrders: false,
            PriceAsString: '',
            Colour: color,
            Guid: GuidHelper.new(),
            SeatCategory: seatCategory,
            GeneralAdmission: false,
            PriceAsInt: 0,
          });
        });
    });

    var allocatedCategoryGroup: ICategoryGroup = {
      Id: 0,
      Index: event.AllocatedCategoryGroups.length,
      SelectedEventDates: event.Dates.map((d) => d.Guid),
      HasOrders: false,
      Categories: categories,
      Guid: GuidHelper.new(),
    };

    event.AllocatedCategoryGroups.push(allocatedCategoryGroup);
    resetIndexes();
  };

  return (
    <>
      <Toolbar>
        <div className="options">
          {props.globalOptions}
          <button className="option" onClick={addNew}>
            <label>Add Seated Ticket</label>
            <div className="icon">
              <SVGTicket />
            </div>
          </button>
        </div>
      </Toolbar>
      <h2>Seated tickets</h2>
      {loading ? (
        <Loader>Loading tickets...</Loader>
      ) : (
        <div className="animated fadeIn">
          <table className="draggable">
            <tbody>
              {getItems().map((group: ICategoryGroup, index) => (
                <tr
                  key={'SeatedTicket_' + index}
                  draggable={!showColourPicker}
                  style={{ display: 'table-row' }}
                  onDragStart={handleDragStart(index, group)}
                  onDragOver={handleDragOver(index, group)}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <td
                    className={`${
                      dragOver && (dragOver.Id ? dragOver.Id : dragOver.Guid) == (group.Id ? group.Id : group.Guid) ? (dragOver.Index > dragging.Index ? ' place-down' : ' place-up') : ''
                    }`}
                  >
                    <SeatedTicket
                      showColourPicker={(value) => setShowColourPicker(value)}
                      symbol={event.CurrencySymbol}
                      handleChange={() => onEventUpdated({ ...event })}
                      onDeleteCategoryGroupClick={() => {
                        event.AllocatedCategoryGroups = event.AllocatedCategoryGroups.filter((x) => x != group);
                        onEventUpdated({ ...event });
                      }}
                      eventDates={event.Dates}
                      index={index}
                      group={group}
                      key={'allocated_category_group_' + index}
                      event={event}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <button className="admin-button" onClick={addNew}>
            Add a seated ticket
          </button>
        </div>
      )}
    </>
  );
};

export default SeatedTicketsSection;
