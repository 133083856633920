import moment from 'moment';
import 'moment-timezone';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import eventMenuState from '../../../../atoms/eventMenuState';
import loaderState from '../../../../atoms/loaderState';
import CacheHelper from '../../../../helpers/CacheHelper';
import NumberHelper from '../../../../helpers/NumberHelper';
import DateRow from './DateRow';

interface IProps {
  event: any;
  lastInGroup?: boolean;
}

const EventRow: React.FC<IProps> = (props) => {
  const [eventDatesVisible, setEventDatesVisible] = useState(false);
  const [loaders, setLoaders] = useRecoilState(loaderState);
  const [, setEventMenu] = useRecoilState(eventMenuState);
  const event: any = props.event;

  const now = event && moment().tz(event.TimeZoneIana);
  const onlineFrom = event && moment(event.OnlineFromDate).tz(event.TimeZoneIana);
  const salesStarted = event && onlineFrom <= now;

  var firstDate = moment.utc(event.Dates[0].EventDate);
  firstDate.utc();
  var lastDate: moment.Moment = null;
  var lastDateFormatted = null;
  var eventDateCell = null;
  var firstDateFormatted: any = firstDate.format('ddd Do MMM YY');

  if (event.Dates.length > 1) {
    lastDate = moment.utc(event.Dates[event.Dates.length - 1].EventDate);
    lastDateFormatted = lastDate.format('ddd Do MMM YY');
  } else {
    firstDateFormatted = (
      <span>
        {firstDate.format('ddd Do MMM YY')}
        <span style={{ color: '#b2bbc3' }}> at </span> {firstDate.format('HH:mm')}
      </span>
    );
  }
  if (lastDate != null) {
    if (firstDate.year() == lastDate.year() && firstDate.month() == lastDate.month() && firstDate.day() == lastDate.day()) {
      lastDate = null;
    } else if (firstDate.year() == lastDate.year()) {
      firstDateFormatted = firstDate.format('ddd Do MMM');
      if (firstDate.month() == lastDate.month()) {
        firstDateFormatted = firstDate.format('ddd Do');
      }
    }
  }

  if (event.Dates.length > 1) {
    eventDateCell = (
      <a href="#!" onClick={() => setEventDatesVisible(!eventDatesVisible)}>
        {firstDateFormatted}
        {lastDate == null ? '' : <span style={{ color: '#b2bbc3' }}> to </span>}
        {lastDate == null ? '' : lastDateFormatted}
      </a>
    );
  } else {
    eventDateCell = (
      <Link to={`/${event.EventTag}/tickets/${moment.utc(event.Dates[event.Dates.length - 1].EventDate).format('DDMMMYYYY/HHmm')}`}>
        {firstDateFormatted}
        {lastDate == null ? '' : <span style={{ color: '#b2bbc3' }}> to </span>}
        {lastDate == null ? '' : lastDateFormatted}
      </Link>
    );
  }

  var dateRows = [];

  if (event.Dates.length > 1 && eventDatesVisible) {
    event.Dates.forEach((date, index) => {
      dateRows.push(<DateRow lastInGroup={index == event.Dates.length - 1} eventDate={date}></DateRow>);
    });
  }

  var soldPercentage = ((event.SoldTickets / event.AvailableTickets) * 100.0).toFixed(1);
  if (!NumberHelper.isNumeric(soldPercentage)) {
    soldPercentage = '0.0';
  }

  return (
    <>
      <tr className="event-row" style={{ height: '100px', borderBottom: props.lastInGroup && !eventDatesVisible ? '2px solid #ebebeb' : null }}>
        <td className="event-image-container">
          <img className="event-image" style={{ display: 'inline-block' }} src={event.ImageUrl} />
        </td>

        <td>
          <div className="event-details">
            <button
              className="name"
              onClick={() => {
                CacheHelper.eventWithMessage(setLoaders, loaders, props.event.EventTag).then((event) => {
                  setEventMenu({ eventTag: event.EventTag });
                });
              }}
            >
              {event.EventName}
            </button>
            <div className="">#{event.EventTag}</div>
            <div className="">{event.VenueName}</div>
            <div className="dates">{eventDateCell}</div>
            {!salesStarted && onlineFrom && <div>Ticket sales start on {onlineFrom.format('Do MMMM YYYY HH:mm')}</div>}
          </div>
        </td>
        <td>
          {event.AvailableTickets > 0 && (
            <>
              {soldPercentage}
              <span style={{ color: '#b2bbc3' }}>%</span>
            </>
          )}
        </td>
        <td>
          {event.SoldTickets}
          <span style={{ color: '#b2bbc3' }}>/{event.AvailableTickets}</span>
        </td>
        <td style={{ textAlign: 'right' }}>
          <span style={{ color: '#b2bbc3' }}>{event.CurrencySymbol}</span>
          <span>{(event.SoldTicketsValue / 100.0).toFixed(2)}</span>
        </td>
      </tr>
      {dateRows}
    </>
  );
};

export default EventRow;
