import linq from 'linq';
import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AdminApi from '../../../../api/AdminApi';
import Loader from '../../../../components/Loader';
import CurrencyHelper from '../../../../helpers/CurrencyHelper';
import { IEvent } from '../../../../interfaces/IEvent';
import { IEventDate } from '../../../../interfaces/IEventDate';
import './AdminSummary.scss';
import EventDateSummary_TicketSales from './EventDateSummary_TicketSales';

export interface IProps {
  event: IEvent;
  eventDate: IEventDate;
}

interface IEventSummary {
  EventDateSales: any;
  OrderDiscounts: any[];
}

const AdminEventDateSummary: FunctionComponent<IProps> = ({ event, eventDate }) => {
  const [summary, setSummary] = useState<IEventSummary>(null);
  const [busyMessage, setBusyMessage] = useState('Loading summary...');

  const load = () => {
    setBusyMessage('Loading summary...');

    AdminApi.request('GET', `/Api/EventDateSummary?eventTag=${event.EventTag}&currencySymbol=${event.CurrencySymbol}&eventDateId=${eventDate.Id}`)
      .then((_summary) => {
        setSummary(_summary);
        setBusyMessage(null);
      })
      .catch((message) => alert(message));
  };
  useEffect(load, []);

  if (busyMessage) return <Loader inline>{busyMessage}</Loader>;

  return (
    <>
      <Helmet>
        <title>
          #{event.EventTag} {moment(eventDate.Date).format('DDMMMYYYY/HHmm')} Summary
        </title>
        <meta name="description" content={`Summary for #${event.EventTag} ${moment(eventDate.Date).format('DDMMMYYYY/HHmm')}`} />
      </Helmet>

      {summary && event && (
        <>
          <div className="toolbar">
            <div className="title">Summary</div>
            <div className="buttons"></div>
          </div>
          <div className="row section">
            <div className="col-sm-7">
              {summary.EventDateSales != null && (
                <>
                  <div className="fields">
                    <h2>Ticket Sales</h2>
                    <EventDateSummary_TicketSales event={event} eventDateSales={summary.EventDateSales} currency={event.CurrencySymbol} />
                  </div>
                </>
              )}
            </div>

            <div className="col-sm-1"></div>
            <div className="col-sm-4">
              <div className="info">Here are your total sales summary for the selected event date.</div>
            </div>
          </div>

          {summary.OrderDiscounts != null && summary.OrderDiscounts.length > 0 && (
            <>
              <div className="spacer"></div>
              <div className="row section">
                <div className="col-sm-7">
                  <div className="fields">
                    <h2>Discounts</h2>
                    {summary.OrderDiscounts.map((discount, index) => (
                      <div key={`Discount_${index}`} className="field">
                        <label className="inline">
                          {discount.Quantity} {discount.Name}
                        </label>
                        <label className="right">{CurrencyHelper.formatCurrency(event.CurrencySymbol, discount.Amount)}</label>
                      </div>
                    ))}
                    <div className="field">
                      <label className="inline">{linq.from(summary.OrderDiscounts).sum((d) => parseInt((d as any).Quantity))} discounts issued in total</label>
                      <label className="right">
                        <strong>
                          {CurrencyHelper.formatCurrency(
                            event.CurrencySymbol,
                            linq.from(summary.OrderDiscounts).sum((d) => (d as any).Amount)
                          )}
                        </strong>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="spacer"></div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AdminEventDateSummary;
