import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import Switch from '../../../components/Switch';
import ColourHelper from '../../../helpers/ColourHelper';
import NumberHelper from '../../../helpers/NumberHelper';
import { ITicketCategory } from '../../../interfaces/ITicketCategory';
import SVGDown from '../../../svg/SVGDown';
import SVGTrash from '../../../svg/SVGTrash';
import SVGPaint from '../../../svg/SVGPaint';

interface IProps {
  symbol: string;
  category: ITicketCategory;
  index: number;
  onNameChange: (value: string) => void;
  onAdditionalInformationChange: (value: string) => void;
  onPeopleChange: (value: string) => void;
  onPriceChange: (value: string) => void;
  onColourChange: (value: string) => void;
  onHideChange: (value: boolean) => void;
  onDeleteCategoryClick: (value: ITicketCategory) => void;
  showColourPicker: (show) => void;
}

const GeneralTicketCategory: React.FC<IProps> = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [price, setPrice] = useState<string>((props.category.PriceAsInt / 100.0).toFixed(2));
  const [priceValid, setPriceValid] = useState<boolean>(NumberHelper.isNumeric((props.category.PriceAsInt / 100.0).toFixed(2)));
  const [showColourPicker, setShowColourPicker] = useState(false);

  var colorHEX = props.category.Colour;
  var colorRGB = ColourHelper.hexToRgb(colorHEX);

  return (
    <div>
      <div className="row" style={{ marginBottom: '0', marginTop: '5px' }}>
        <div className="col-sm-4">
          <InfoInput labelText="Ticket name" onChange={(value) => props.onNameChange(value)} value={props.category.Name} />
        </div>
        <div className="col-sm-2">
          <InfoInput
            symbol={props.symbol}
            onBlur={() => {
              props.onPriceChange(price);
              if (priceValid) setPrice(parseFloat(price).toFixed(2));
            }}
            labelColour={priceValid ? null : 'red'}
            labelText={'Price' + (priceValid ? '' : ' (Invalid)')}
            onChange={(value) => {
              setPriceValid(NumberHelper.isNumeric(value));
              setPrice(value);
              props.onPriceChange(value);
            }}
            value={price}
          />
        </div>
        <div className="col-sm-2" style={{ padding: '10px' }}>
          <Switch trueLabel="Public" falseLabel="Private" onChange={(value) => props.onHideChange(!value)} checked={!props.category.Hide} />
        </div>
        <div className="col-sm-4 flex_from_right">
          <button style={{ marginLeft: '5px' }} className={`admin-button icon ${expanded ? 'flip' : ''}`} onClick={() => setExpanded(!expanded)}>
            <SVGDown />
          </button>

          <div className="colour-picker-button">
            <button
              style={{ background: 'rgba(' + colorRGB.r + ', ' + colorRGB.g + ', ' + colorRGB.b + ', 1)', marginLeft: '5px' }}
              className={`admin-button colour-picker icon`}
              onClick={() => {
                props.showColourPicker(true);
                setShowColourPicker(true);
              }}
            >
              <SVGPaint />
            </button>

            {showColourPicker && (
              <>
                <div
                  className="click-off"
                  onClick={() => {
                    setShowColourPicker(false);
                    props.showColourPicker(false);
                  }}
                ></div>
                <ChromePicker
                  disableAlpha={true}
                  color={colorHEX}
                  onChange={(value) => {
                    props.onColourChange(value.hex);
                  }}
                />
              </>
            )}
          </div>

          {props.category.HasOrders ? null : (
            <button style={{ marginLeft: '5px' }} className="admin-button icon bad" onClick={() => props.onDeleteCategoryClick(props.category)}>
              <SVGTrash />
            </button>
          )}
        </div>
      </div>

      <div className="row" style={{ display: expanded ? 'block' : 'none', paddingBottom: '30px' }}>
        <div className="col-sm-8">
          <InfoLabel
            text="How many people does this ticket represent?"
            tooltip="This value represents the quantity of people each of this type of ticket counts for. If you leave it blank we will set it to the default of 1. So if you have a family ticket with 4 people, set this value to 4 and that value will come off of the quntity available each time someone orders this ticket.."
          />
          <div style={{ maxWidth: '200px' }}>
            <InfoInput labelText="People" onChange={(value) => props.onPeopleChange(value)} value={props.category.People.toString()} />
          </div>

          <div className="section">
            <InfoLabel text="Any additional info for this ticket?" tooltip="This is an optional field. You can add some additional information that will show on this general admission ticket." />
            <InfoInput rows={2} labelText="Additional Information" onChange={(value) => props.onAdditionalInformationChange(value)} value={props.category.Description} />
          </div>
        </div>
        <div className="col-sm-4">
          {props.category.HasOrders ? (
            <div className="info">
              This ticket has <strong>existing orders</strong> placed against it and cannot be deleted. If you want to make it unavailable to the public, you can make all seat categories private.
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default GeneralTicketCategory;
