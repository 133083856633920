import linq from 'linq';
import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import Dropdown from '../../../components/Dropdown';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import ColourHelper from '../../../helpers/ColourHelper';
import DateHelper from '../../../helpers/DateHelper';
import GuidHelper from '../../../helpers/GuidHelper';
import NumberHelper from '../../../helpers/NumberHelper';
import { ICategoryGroup, IEvent } from '../../../interfaces/IEvent';
import { IEventDate } from '../../../interfaces/IEventDate';
import { ITicketCategory } from '../../../interfaces/ITicketCategory';
import { ISeatingPlan } from '../../../interfaces/ISeatingPlan';
import { ISeatCategory } from '../../../interfaces/ISeatCategory';
import moment from 'moment';
import SVGTrash from '../../../svg/SVGTrash';
import SVGDown from '../../../svg/SVGDown';
import SVGPaint from '../../../svg/SVGPaint';
import SeatedTicketCategory from './SeatedTicketCategory';
import { IDropDownItem } from '../../../components/DropdownItem';

interface IProps {
  symbol: string;
  handleChange: () => void;
  onDeleteCategoryGroupClick: (group) => void;
  eventDates: IEventDate[];
  index: number;
  group: ICategoryGroup;
  event: IEvent;
  className?: string;
  showColourPicker: (show) => void;
}

const SeatedTicket: React.FC<IProps> = (props) => {
  var group = props.group;
  const event = props.event;
  const [expanded, setExpanded] = useState(!group.Id || group.Id <= 0);
  const [showColourPicker, setShowColourPicker] = useState(false);

  const handleChangeAndMarkSelectedDates = () => {
    group.SelectedEventDates.forEach((guid) => {
      const ed = linq.from(props.eventDates).firstOrDefault((ed) => ed.Guid == guid);
      if (ed) ed.ChangeMade = true;
    });

    props.handleChange();
  };

  useEffect(() => {
    if (!props.group || !props.group.Categories || props.group.Categories.length === 0) return;
    const amountBefore = props.group.Categories.length;

    props.group.Categories = linq
      .from(props.group.Categories)
      .groupBy((g: any) => g.SeatCategory.Id)
      .select((g) => g.first())
      .toArray();

    if (props.group.Categories.length !== amountBefore) {
      handleChangeAndMarkSelectedDates();
    }
  }, []);

  const handleDeleteCategoryGroupClick = function () {
    if (props.onDeleteCategoryGroupClick) {
      group.SelectedEventDates.forEach((guid) => {
        const ed = linq.from(props.eventDates).firstOrDefault((ed) => ed.Guid == guid);
        if (ed) ed.ChangeMade = true;
      });

      props.onDeleteCategoryGroupClick(props.group);
    }
  };

  const handleNameChange = function (value) {
    props.group.Categories.forEach((category, index) => {
      category.Name = value;
    });
    handleChangeAndMarkSelectedDates();
  };

  var category = group.Categories[0];
  var colorHEX = category ? category.Colour : '#000000';
  var colorRGB = ColourHelper.hexToRgb(colorHEX);

  if (group.Guid == null) {
    group.Guid = GuidHelper.new();
  }

  const items = linq
    .from(props.eventDates)
    .orderBy((ed) => moment(ed.DateAsString).unix())
    .toArray()
    .map((d) => {
      return {
        groupText: moment.utc(d.Date).startOf('day').format('dddd Do MMMM YYYY'),
        group: moment.utc(d.Date).startOf('day').unix(),
        value: d.Guid,
        description: moment.utc(d.Date).format('h:mma'),
      };
    });
  return (
    <>
      <div className={`expandable-row${props.className ? ' ' + props.className : ''}`}>
        <div className="main-area flex_from_left">
          <div className="info" style={{ marginBottom: expanded ? '10px' : '0' }}>
            <strong style={{ color: category.Colour }}>{category.Name.length == 0 ? 'Ticket' : category.Name}</strong> Available on {group.SelectedEventDates.length}/{props.eventDates.length} event
            dates. {group.Categories.filter((c) => !c.Hide).length} prices available to the public.
          </div>
        </div>
        <div className="flex_from_right">
          <button style={{ marginLeft: '5px' }} className={`admin-button icon ${expanded ? 'flip' : ''}`} onClick={() => setExpanded(!expanded)}>
            <SVGDown />
          </button>

          <div className="colour-picker-button">
            <button
              style={{ background: 'rgba(' + colorRGB.r + ', ' + colorRGB.g + ', ' + colorRGB.b + ', 1)', marginLeft: '5px' }}
              className={`admin-button colour-picker icon`}
              onClick={() => {
                props.showColourPicker(true);
                setShowColourPicker(true);
              }}
            >
              <SVGPaint />
            </button>

            {showColourPicker && (
              <>
                <div
                  className="click-off"
                  onClick={() => {
                    setShowColourPicker(false);
                    props.showColourPicker(false);
                  }}
                ></div>
                <ChromePicker
                  disableAlpha={true}
                  color={colorHEX}
                  onChange={(value) => {
                    props.group.Categories.forEach((c) => {
                      c.Colour = value.hex;
                    });
                    handleChangeAndMarkSelectedDates();
                  }}
                />
              </>
            )}
          </div>

          {props.group.HasOrders ? null : (
            <button style={{ marginLeft: '5px' }} className="admin-button icon bad" onClick={handleDeleteCategoryGroupClick}>
              <SVGTrash />
            </button>
          )}
        </div>
      </div>

      {expanded && (
        <>
          <InfoLabel
            text={`Seated Ticket Options`}
            tooltip={`The below ticket, seat category and price options are for a single ticket category. You can have as many ticket categories as you require to get the set up that you need for your event. An event has many ticket categories which each have a price for each seat category in your seating plan. Each of those can be made Private which means only an administrator of the organisation will be able to see or book them and they will not be available to the public.`}
          />
          <div className="row">
            <div className="col-sm-8">
              <div className="fields">
                <div className="field">
                  <div className="row">
                    <div className="col-sm-8">
                      <InfoInput labelText="Ticket name" onChange={handleNameChange} value={category.Name} />
                    </div>
                    <div className="col-sm-4"></div>
                  </div>
                  <div className="row">
                    <div className="col-sm-8">
                      <div className="input-label-group" style={{ paddingTop: 0 }}>
                        {
                          <Dropdown
                            multiple
                            description="Available dates"
                            items={
                              items.length > 2
                                ? [
                                    {
                                      value: 'all',
                                      description: 'Toggle all',
                                      group: null,
                                      ignoreCount: true,
                                    },
                                    ...items,
                                  ]
                                : items
                            }
                            selectAllGroupClicked={(items) => {
                              items.forEach((item) => {
                                const eventDate = props.eventDates.find((ed) => ed.Guid === item.value) || null;
                                if (eventDate) {
                                  eventDate.ChangeMade = true;
                                }
                                props.handleChange();
                              });
                            }}
                            onChange={(value: IDropDownItem[], item: IDropDownItem) => {
                              const isAllSelected = item && item.value === 'all';
                              const eventDatesMap = props.eventDates.map((ed) => ({ ...ed, ChangeMade: true }));

                              if (isAllSelected) {
                                group.SelectedEventDates = value.length - 1 === props.eventDates.length ? [] : eventDatesMap.map((ed) => ed.Guid);
                                props.handleChange();
                                return;
                              }

                              if (item) {
                                const eventDate = props.eventDates.find((ed) => ed.Guid === item.value) || null;
                                eventDate.ChangeMade = true;
                              }

                              group.SelectedEventDates = value.map((v) => v.value);

                              props.handleChange();
                            }}
                            value={group.SelectedEventDates}
                          />
                        }
                        <label className="input-label animated fadeIn">Available dates</label>
                      </div>
                    </div>
                    <div className="col-sm-4"></div>
                  </div>
                </div>

                {linq
                  .from(event.SeatingPlans)
                  .orderBy((seatingPlan) => seatingPlan.Index)
                  .toArray()
                  .map((seatingPlan: ISeatingPlan, index) => {
                    return (
                      <div className="field" key={seatingPlan.Id}>
                        <InfoLabel
                          text={event.SeatingPlans.length == 0 ? `Seat Categories & Prices` : `${seatingPlan.Name} Seat Categories & Prices`}
                          tooltip={`
                        For each ticket you can assign a price to a seat category. You can have multiple tickets with different prices for each seat category. Such as a simple example of an Adult ticket and then a Child ticket with different prices for a seat category such as Stalls.
                        You can make seat categories within a ticket private, which will mean they can only be ordered by an organisation administrator and will not be openly on sale to the general public.`}
                        />

                        {linq
                          .from(seatingPlan.SeatCategories)
                          .orderBy((sc) => sc.Name)
                          .toArray()
                          .map((seatCategory: ISeatCategory) => {
                            let category: ITicketCategory = linq
                              .from(group.Categories)
                              .firstOrDefault((c: ITicketCategory) => c.SeatCategory && (seatCategory.Id ? c.SeatCategory.Id == seatCategory.Id : c.SeatCategory.Guid == seatCategory.Guid));

                            if (!category) {
                              category = {
                                Id: 0,
                                People: 1,
                                Description: '',
                                Name: '',
                                HasOrders: false,
                                PriceAsString: '',
                                Colour: ColourHelper.getRandomColor(),
                                Guid: GuidHelper.new(),
                                SeatCategory: seatCategory,
                                GeneralAdmission: false,
                                PriceAsInt: 0,
                              };
                              group.Categories.push(category);
                            }

                            if (category.Guid == null) {
                              category.Guid = GuidHelper.new();
                            }
                            return (
                              <SeatedTicketCategory
                                onPriceChange={(value: string) => {
                                  if (NumberHelper.isNumeric(value)) {
                                    var parsedValue = parseFloat(value);
                                    category.PriceAsString = parsedValue.toFixed(2);
                                    category.PriceAsInt = parseInt((parseFloat(parsedValue.toFixed(2)) * 100.0).toString());
                                  } else {
                                    category.PriceAsString = '';
                                    category.PriceAsInt = 0;
                                  }
                                  handleChangeAndMarkSelectedDates();
                                }}
                                onHideChange={(value) => {
                                  category.Hide = value;
                                  handleChangeAndMarkSelectedDates();
                                }}
                                symbol={props.symbol}
                                category={category}
                                index={index}
                                key={'Category_' + category.Id + '_' + category.Guid}
                              />
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="col-sm-4">
              {props.group.HasOrders ? (
                <div className="info">
                  This ticket has <strong>existing orders</strong> placed against it and cannot be deleted. If you want to make it unavailable to the public, you can make all seat categories private.
                </div>
              ) : null}
            </div>
          </div>

          <div className="spacer"></div>
        </>
      )}
    </>
  );
};

export default SeatedTicket;
